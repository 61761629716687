import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/site/Layout";
import PanelLayout from "./layouts/panel/PanelLayout";
import Landing from "./views/Landing/Landing";
import Contact from "./views/Contact/Contact";
import Offer from "./views/Offer/Offer";
import Gallery from "./views/Gallery/Gallery";
import GGroup from "./views/GGroup/GGroup";
import NoMatch from "./views/NoMatch/NoMatch";
import Login from "./views/panel/Login/Login";
import AuthGateway from "./components/AuthGateway/AuthGateway";
import GroupsGallery from "./views/GroupsGallery/GroupsGallery";
import Dashboard from "./views/panel/Dashboard/Dashboard";
import PanelGallery from "./views/panel/PanelGallery/PanelGallery";
import PanelGalleryGroup from "./views/panel/PanelGalleryGroup/PanelGalleryGroup";
import AddImages from "./views/panel/AddImages/AddImages";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout invertPaths={["/"]} />}>
        <Route index element={<Landing />} />
        <Route path="kontakt" element={<Contact />} />
        <Route path="oferta" element={<Offer />} />
        <Route path="galeria">
          <Route index element={<Gallery />} />
          <Route path="grupy">
            <Route index element={<GroupsGallery />} />
            <Route path=":groupId" element={<GGroup />} />
          </Route>
        </Route>
      </Route>

      <Route path="admin">
        <Route path="panel">
          <Route element={<PanelLayout />}>
            <Route path="*" element={<AuthGateway />}>
              <Route index element={<Dashboard />} />
              <Route path="galeria">
                <Route index element={<PanelGallery />} />
                <Route path="dodaj-zdjecie" element={<AddImages />} />
                <Route path="grupa/:groupId" element={<PanelGalleryGroup />} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
        </Route>
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;
