import React, { FC, FunctionComponent, SVGProps } from "react";
import Loader from "../Loader/Loader";

export interface ButtonProps {
  text: string;
  Icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  action: "callback" | "link";
  path?: string;
  isSubmit?: boolean;
  filled?: boolean;
  color?: "black" | "white" | "primary" | "red";
  callback?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isPanelButton?: boolean;
}

const Button: FC<ButtonProps> = ({
  text,
  Icon,
  action,
  isSubmit = false,
  path = "/",
  filled = false,
  color = "black",
  callback,
  isLoading,
  disabled = false,
  isPanelButton,
}) => {
  const CLS = "btn";

  const onButtonClick = (): void => {
    if (callback) callback();
  };

  return (
    <div
      className="button-holder flex y-center"
      data-filled={filled}
      data-color={color}
      data-is-panel={isPanelButton}
    >
      {action === "callback" ? (
        <button
          className={CLS}
          type={isSubmit ? "submit" : "button"}
          onClick={onButtonClick}
          disabled={disabled}
        >
          {isLoading ? (
            <Loader size="small" />
          ) : Icon ? (
            <Icon className="icon" width={24} height={24} />
          ) : (
            <></>
          )}
          <span>{text}</span>
        </button>
      ) : (
        <a className={CLS} href={path}>
          {isLoading ? (
            <Loader size="small" />
          ) : Icon ? (
            <Icon className="icon" width={24} height={24} />
          ) : (
            <></>
          )}
          <span>{text}</span>
        </a>
      )}
    </div>
  );
};

export default Button;
