import React, { FC, useEffect, useState } from "react";
import Select, { SelectOption } from "../../../components/Select/Select";
import PanelButton from "../../../components/PanelButton/PanelButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useSearchParams } from "react-router-dom";
import GalleryItem from "../../../interfaces/GalleryItem";
import { getGallery } from "../../../firebase/api/gallery/getGallery";
import {
  getGroupsGallery,
  GroupsGalleryData,
} from "../../../firebase/api/gallery/getGroupsGallery";
import SkeletonGalleryItemTile from "../../../components/skeleton/SkeletonGalleryItemTile/SkeletonGalleryItemTile";
import GalleryItemTile from "../../../components/GalleryItemTile/GalleryItemTile";
import FullscreenImageView from "../../../components/FullscreenImageView/FullscreenImageView";
import GalleryGroupItemTile from "../../../components/GalleryGroupItemTile/GalleryGroupItemTile";
import SkeletonGalleryGroupItemTile from "../../../components/skeleton/SkeletonGalleryGroupItemTile/SkeletonGalleryGroupItemTile";
import { AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import PanelActionsBar, {
  PanelActionBarAction,
} from "../../../components/PanelActionsBar/PanelActionsBar";
import { ReactComponent as BinIcon } from "../../../assets/svg/bin.svg";
import { deleteImages } from "../../../firebase/api/gallery/deleteImages";

const PanelGallery: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [images, setImages] = useState<GalleryItem[] | null>(null);
  const [groups, setGroups] = useState<GroupsGalleryData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFullscreenViewVisible, setIsFullscreenViewVisible] =
    useState<boolean>(false);
  const [clickedGalleryItem, setClickedGalleryItem] =
    useState<GalleryItem | null>(null);
  const [currentMode, setCurrentMode] = useState<"images" | "groups" | string>(
    "images"
  );
  const [isPanelActionsBarVisible, setIsPanelActionsBarVisible] =
    useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<GalleryItem[]>([]);

  useEffect(() => {
    if (!searchParams.get("mode")) {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev.entries()),
        mode: "images",
      }));
    } else {
      const spMode = searchParams.get("mode") || "images";
      if (["images", "groups"].includes(spMode)) {
        setCurrentMode(spMode);
      }
    }

    (async () => {
      switch (currentMode) {
        case "images":
          {
            if (!images) {
              try {
                setIsLoading(true);
                const i = await getGallery({}, false);
                if (!i) return;

                setImages(i.filter((item) => !item?.isArchived));
                setIsLoading(false);
              } catch (error) {
                setError("Wystąpił błąd podczas pobierania zdjęć");
              }
            }
          }
          break;

        case "groups":
          {
            if (!groups) {
              try {
                setIsLoading(true);
                const g = await getGroupsGallery({});
                if (!g) return;

                setGroups(g);
                setIsLoading(false);
              } catch (error) {
                setError("Wystąpił błąd podczas pobierania grup zdjęć");
              }
            }
          }
          break;
      }
    })();
  }, [searchParams.get("mode"), currentMode]);

  useEffect(() => {
    const view = searchParams.get("view") || "grid";
    if (view === "fullscreen" && images) {
      const imageId: string | null = searchParams.get("imageId") || null;

      if (imageId) {
        showFullscreenView(images.find((item) => item.id === imageId));
      }
    }
  }, [searchParams.get("view"), searchParams.get("imageId")]);

  useEffect(() => {
    setIsPanelActionsBarVisible(selectedImages.length > 0);
  }, [selectedImages]);

  const onItemsViewSelectChange = (option: SelectOption) => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      mode: option.id,
    }));
  };

  const onSelectItemsChange = (status: boolean): void => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      select: "" + (status ? 1 : 0),
    }));
  };

  const showFullscreenView = (item: GalleryItem | undefined): void => {
    if (!item) return;

    setIsFullscreenViewVisible(true);
    setClickedGalleryItem(item);
  };

  const onFullscreenViewHide = (): void => {
    setIsFullscreenViewVisible(false);
    setClickedGalleryItem(null);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "grid",
      imageId: "",
    }));
  };

  const onGalleryItemClick = (item: GalleryItem): void => {
    showFullscreenView(item);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "fullscreen",
      imageId: item.id,
    }));
  };

  const onImageSelect = (image: GalleryItem, status: boolean): void => {
    if (status) {
      setSelectedImages((prev) => [image, ...prev]);
    } else {
      setSelectedImages((prev) => prev.filter((item) => item.id !== image.id));
    }
  };

  const ItemsViewSelectOptions: SelectOption[] = [
    {
      id: "images",
      label: "Zdjęcia",
      isDefault: (searchParams.get("mode") || "images") === "images",
    },
    {
      id: "groups",
      label: "Grupy",
      isDefault: (searchParams.get("mode") || "images") === "groups",
    },
  ];

  const PanelActionsBarActions: PanelActionBarAction[] = [
    {
      id: "delete",
      name: "Usuń",
      callback: async () => {
        const result = await deleteImages(selectedImages);
        setImages((prev) =>
          prev!.filter(
            (item) => !selectedImages.map((i) => i.id).includes(item.id)
          )
        );
        setSelectedImages([]);
        setIsPanelActionsBarVisible(false);
        setSearchParams((prev) => ({
          ...Object.fromEntries(prev.entries()),
          select: "" + 0,
        }));
      },
      Icon: BinIcon,
    },
  ];

  return (
    <section id="panel-gallery-view" className="panel-view">
      <main className="content f-column y-start x-start">
        <div className="actions-bar flex y-center x-between">
          <div className="select-wrapper">
            <Select
              label="Widok"
              name="Widok"
              options={ItemsViewSelectOptions}
              onChange={onItemsViewSelectChange}
            />
          </div>

          <div className="actions-wrapper flex y-center x-end">
            <Checkbox
              id="select-checkbox"
              label="Zaznacz"
              onChange={onSelectItemsChange}
            />

            <PanelButton
              text="Dodaj"
              action="link"
              path="/admin/panel/galeria/dodaj-zdjecie"
            />
          </div>
        </div>
        <div className="panel-gallery-images-wrapper f-column">
          <div className="gallery-content-bar">
            {!isLoading ? (
              <span>Wyniki ({images?.length || 0})</span>
            ) : (
              <Skeleton width={100} />
            )}
          </div>

          <AnimatePresence mode="popLayout">
            <div className="gallery-items-wrapper">
              {currentMode === "images" ? (
                <div className="gallery-items">
                  {!isLoading
                    ? images &&
                      images?.map((image) => (
                        <GalleryItemTile
                          data={image}
                          onClick={onGalleryItemClick}
                          key={image.id}
                          isSelecting={
                            !!Number(searchParams.get("select") || 0)
                          }
                          onSelect={(status) => onImageSelect(image, status)}
                        />
                      ))
                    : Array(8)
                        .fill(2)
                        .map(Math.pow)
                        .map((s) => <SkeletonGalleryItemTile key={s} />)}

                  <FullscreenImageView
                    current={clickedGalleryItem}
                    isVisible={isFullscreenViewVisible}
                    onHide={onFullscreenViewHide}
                    gallery={images}
                  />
                </div>
              ) : (
                <div className="gallery-groups">
                  {!isLoading
                    ? groups &&
                      Object.keys(groups)?.map((groupId) => (
                        <GalleryGroupItemTile
                          data={groups[groupId]}
                          isPanel
                          key={groupId}
                        />
                      ))
                    : Array(8)
                        .fill(2)
                        .map(Math.pow)
                        .map((s) => <SkeletonGalleryGroupItemTile key={s} />)}
                </div>
              )}
            </div>
          </AnimatePresence>
        </div>
      </main>

      <PanelActionsBar
        actions={PanelActionsBarActions}
        isVisible={isPanelActionsBarVisible}
      />
    </section>
  );
};

export default PanelGallery;
