import React, { FC, useState } from "react";
import MenuBg from "../../assets/webp/empty_floor.jpeg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { motion, Variants } from "framer-motion";
import { FadeUpVariants } from "../../config/variants";

const NoMatch: FC = () => {
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const onImageLoad = (): void => {
    setImageLoaded(true);
  };

  const onComebackClick = () => {
    navigate(-1);
  };

  const ImageVariants: Variants = {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
        type: "easeOut",
      },
    },
  };

  const TextBoxVariants: Variants = {
    initial: {
      width: 0,
      opacity: 0,
    },

    fadeUp: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "easeOut",
        staggerChildren: 0.3,
        delayChildren: 0.8,
      },
    },
  };

  return (
    <section id="no-match">
      <main className="content flex f-column y-center x-center">
        <motion.div
          className="text-content f-column y-start x-start"
          variants={TextBoxVariants}
          initial="initial"
          animate={imageLoaded ? "fadeUp" : "initial"}
        >
          <motion.div
            className="logo-motion-element"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, type: "easeOut", delay: 2 }}
          >
            <Logo size="small" />
          </motion.div>

          <motion.header
            variants={FadeUpVariants}
            transition={{ duration: 0.3, type: "easeOut" }}
          >
            Tu jeszcze płytek nie położyliśmy.
          </motion.header>

          <motion.p
            variants={FadeUpVariants}
            transition={{ duration: 0.3, type: "easeOut" }}
          >
            Ta strona nie istnieje, wygasła lub jest w trakcie budowy.
          </motion.p>

          <motion.div
            className="button-motion-element"
            variants={FadeUpVariants}
            transition={{ duration: 0.3, type: "easeOut" }}
          >
            <Button text="Wróć" action="callback" callback={onComebackClick} />
          </motion.div>
        </motion.div>

        <div className="content-image flex">
          <span>404</span>
          <motion.img
            src={MenuBg}
            alt="Content image"
            variants={ImageVariants}
            initial="initial"
            animate="animate"
            onLoad={onImageLoad}
          />
        </div>
      </main>
    </section>
  );
};

export default NoMatch;
