import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonGalleryGroupItemTile: FC = () => {
  return (
    <div className="skeleton-gallery-item-tile flex f-column">
      <Skeleton
        height="100%"
        width="100%"
        containerClassName="flex-1 skeleton-gallery-item-tile"
      />

      <div className="text-content flex y-center x-between">
        <Skeleton height={10} width={60} />
        <Skeleton height={10} width={100} />
      </div>
      <div></div>
    </div>
  );
};

export default SkeletonGalleryGroupItemTile;
