import React, { FC, useState } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface CheckboxProps {
  id: string;
  label: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: string;
  options?: RegisterOptions;
  onChange?: (status: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  register,
  required,
  options,
  error,
  onChange,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  const onCheckboxClick = () => {
    if (onChange) onChange(!checked);
    setChecked((prev) => !prev);
  };

  return (
    <div
      className="checkbox f-column y-start x-start"
      onClick={onCheckboxClick}
      data-checked={checked}
    >
      <div className="checkbox-wrapper flex y-center">
        <input
          type="checkbox"
          id={id}
          defaultChecked={checked}
          {...(register ? register(label, { required, ...options }) : null)}
        />
        <label htmlFor={id}>{label}</label>
      </div>

      {error && <small className="error">{error}</small>}
    </div>
  );
};

export default Checkbox;
