import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonGalleryItemTile: FC = () => {
  return (
    <div className="skeleton-gallery-item-tile flex">
      <Skeleton
        height="100%"
        width="100%"
        containerClassName="flex-1 skeleton-gallery-item-tile"
      />
      <div></div>
    </div>
  );
};

export default SkeletonGalleryItemTile;
