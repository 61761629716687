import NavLink from "../interfaces/NavLink";
import { ReactComponent as HomeIcon } from "../assets/svg/home.svg";
import { ReactComponent as GalleryIcon } from "../assets/svg/gallery.svg";
import { ReactComponent as AlertIcon } from "../assets/svg/alert.svg";

export const NAV_LINKS: NavLink[] = [
  {
    id: 0,
    name: "Strona główna",
    link: "/",
    icon: null,
  },
  {
    id: 1,
    name: "Oferta",
    link: "/oferta",
    icon: null,
  },
  {
    id: 2,
    name: "Galeria",
    link: "/galeria",
    icon: null,
  },
  {
    id: 3,
    name: "Kontakt",
    link: "/kontakt",
    icon: null,
  },
];

export const PANEL_NAV_LINKS: NavLink[] = [
  {
    id: 0,
    name: "Panel",
    link: "/admin/panel/",
    icon: HomeIcon,
  },
  {
    id: 1,
    name: "Galeria",
    link: "/admin/panel/galeria",
    icon: GalleryIcon,
  },
  {
    id: 2,
    name: "Alerty",
    link: "/admin/panel/alerty",
    icon: AlertIcon,
  },
];

export const AUTH_COOKIE = "auth";
export const COOKIES_AGREEMENT_COOKIE = "cookies-accepted";
export const MAX_FILE_SIZE = 4 * 1024 * 1024;
export const ACCEPTED_FILE_TYPES: string[] = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/webp",
];
