import { Variants } from "framer-motion";

export const CustomEase1 = [0.6, 0.01, -0.05, 0.95];

export const FadeUpVariants: Variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  fadeUp: {
    opacity: 1,
    y: 0,
  },
};

export const FadeRightVariants: Variants = {
  initial: {
    opacity: 0,
    x: -50,
  },
  fadeRight: {
    opacity: 1,
    x: 0,
  },
};

export const FadeLeftVariants: Variants = {
  initial: {
    opacity: 0,
    x: 50,
  },
  fadeLeft: {
    opacity: 1,
    x: 0,
  },
};
