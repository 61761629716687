import React, { FC, useState } from "react";
import { InputProps } from "../Input/Input";
import { ReactComponent as ClosedEye } from "../../assets/svg/crossed-eye.svg";
import { ReactComponent as OpenedEye } from "../../assets/svg/eye.svg";

interface PasswordInputProps extends InputProps {}

const PasswordInput: FC<PasswordInputProps> = ({
  id,
  label,
  example,
  required,
  register,
  error,
  options,
}) => {
  const SIZE: number = 24;
  const [hidden, setHidden] = useState<boolean>(true);
  const onEyeClick = (): void => {
    setHidden((prev) => !prev);
  };

  return (
    <div className="input-wrapper password f-column y-start x-start">
      <label htmlFor={id}>{label}</label>
      <div className="input-wrapper-content">
        <input
          type={hidden ? "password" : "text"}
          id={id}
          placeholder={example || ""}
          {...register(label, { required, ...options })}
        />
        <div className="eye-icon" onClick={onEyeClick}>
          {hidden ? (
            <ClosedEye width={SIZE} height={SIZE} />
          ) : (
            <OpenedEye width={SIZE} height={SIZE} />
          )}
        </div>
      </div>
      <small className="error">{error}</small>
    </div>
  );
};

export default PasswordInput;
