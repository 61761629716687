import React, { FC } from "react";

interface LogoProps {
  size?: "small" | "big";
}

const Logo: FC<LogoProps> = ({ size = "big" }) => {
  return (
    <div className="logo-wrapper" data-size={size}>
      <a href="/" className="logo">
        <span className="c-primary">Rem</span>
        <span>Art</span>
      </a>
    </div>
  );
};

export default Logo;
