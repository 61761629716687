import React, { FC, useLayoutEffect, useState } from "react";
import Logo from "../../../components/Logo/Logo";
import LoginForm, { LoginFormValues } from "./LoginForm";
import { LoginResult } from "../../../firebase/api/auth/signUp";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Cookie from "js-cookie";
import { AUTH_COOKIE } from "../../../config";

// 918gbJuB1ton

const Login: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login, auth } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (data: LoginFormValues): Promise<void> => {
    setError(null);

    const payload: { email: string; passwd: string } = {
      email: data["Adres e-mail"],
      passwd: data["Hasło"],
    };

    const result: LoginResult = await login(payload);

    if (result.status === "error") {
      setError(result.error);
      return;
    }

    navigate(
      location.state?.from ? location.state.from?.pathname : "/admin/panel/"
    );
  };

  useLayoutEffect(() => {
    if (auth?.id || Cookie.get(AUTH_COOKIE)) {
      navigate(
        location.state?.from ? location.state.from?.pathname : "/admin/panel/",
        { replace: true }
      );
    }
  }, [auth]);

  return (
    <section id="login-view">
      <main className="content f-column y-center x-center">
        <div className="login-box f-column y-center x-center">
          <Logo size="small" />
          <header>Zaloguj się</header>
          <LoginForm onFormSubmit={onSubmit} />
          {error && <small className="error">{error}</small>}
        </div>
      </main>
    </section>
  );
};

export default Login;
