import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../init";
import { FIRESTORE_COLLECTIONS, STORAGE_FOLDERS } from "../../config";
import { getDownloadURL, ref } from "firebase/storage";
import { UploadResultType } from "./uploadImage";

export type ImagesPayloadType = {
  [key: string]: {
    url: string | null;
    uuid: string | null;
    tags?: string[];
    isHighlighted?: boolean;
    file: File;
  };
};

export const addImages = async (
  payload: ImagesPayloadType,
  groupId?: string
): Promise<UploadResultType> => {
  const galleryCollection = collection(db, FIRESTORE_COLLECTIONS.GALLERY);

  if (!payload) return { status: "error", error: "Dane nie poprawne" };

  const result: UploadResultType = {
    status: "success",
    error: null,
  };

  Object.values(payload).map(async (set) => {
    const thumbnailRef = ref(
      storage,
      STORAGE_FOLDERS.GALLERY + "/thumbnails/" + set.uuid + "_128x128"
    );

    await getDownloadURL(thumbnailRef)
      .then(async (thumbnailURL) => {
        try {
          await addDoc(galleryCollection, {
            createdAt: serverTimestamp(),
            url: set.url,
            thumbnail_url: thumbnailURL,
            ...(set.isHighlighted && { isHighlighted: set.isHighlighted }),
            ...(groupId && { groupId }),
            ...(set.tags
              ? {
                  tags: set.tags.reduce(
                    (acc, curr) => ({ ...acc, [curr]: true }),
                    {}
                  ),
                }
              : { tags: {} }),
          });
        } catch (e) {
          result.error = "Wystąpił błąd podczas dodawania dokumentu";
          result.status = "error";
        }
      })
      .catch((e) => {
        result.error = "Wystąpił błąd podczas pobierania adresu url zdjęcia";
        result.status = "error";
      });
  });

  return result;
};
