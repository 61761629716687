import React, { FC } from "react";
import { motion } from "framer-motion";
import Select, { SelectOption } from "../Select/Select";
import GalleryTag from "../GalleryTag/GalleryTag";
import { useSearchParams } from "react-router-dom";

const DateSortSelectOptions: SelectOption[] = [
  {
    id: "oldest",
    label: "Najstarsze",
  },
  {
    id: "newest",
    label: "Najnowsze",
  },
  {
    id: "highlighted",
    label: "Wyróżnione",
  },
];

interface GalleryFiltersProps {
  tags: string[];
  activeTags: string[];
}

const GalleryFilters: FC<GalleryFiltersProps> = ({ tags, activeTags }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDateSortSelectChange = (option: SelectOption): void => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      sort: option.id,
    }));
  };

  const onTagClick = (tag: string): void => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      tags: activeTags.includes(tag)
        ? activeTags.filter((active) => active !== tag).join(",")
        : [...activeTags, tag].join(","),
    }));
  };

  return (
    <div className="gallery-filters flex x-center f-column">
      <motion.div
        className="date-sort-wrapper"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.9,
            duration: 0.3,
          },
        }}
      >
        <Select
          label="Sortuj"
          name="date-sort"
          options={DateSortSelectOptions}
          onChange={onDateSortSelectChange}
          activeId={searchParams.get("sort") || "newest"}
        />
      </motion.div>

      <div className="gallery-filter-tags">
        {tags?.map((tag, i) => (
          <GalleryTag
            text={tag}
            onClick={onTagClick}
            delay={1 + 0.2 * i}
            isActive={activeTags.includes(tag)}
            key={tag}
          />
        ))}
      </div>
    </div>
  );
};

export default GalleryFilters;
