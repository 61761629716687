import React, { FC } from "react";

interface LoaderProps {
  size?: "big" | "small";
}

const Loader: FC<LoaderProps> = ({ size = "big" }) => {
  return (
    <div className="loader-wrapper" data-size={size}>
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
