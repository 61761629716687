import React, { FC, useEffect, useState } from "react";
import ComebackButton from "../../../components/ComebackButton/ComebackButton";
import ImageUploadBox from "../../../components/ImageUploadBox/ImageUploadBox";
import Select, { SelectOption } from "../../../components/Select/Select";
import { getGroups } from "../../../firebase/api/gallery/getGroups";
import AddImageItem from "../../../components/AddImageItem/AddImageItem";
import { AnimatePresence } from "framer-motion";
import { getCategories } from "../../../firebase/api/gallery/getCategories";
import PanelButton from "../../../components/PanelButton/PanelButton";
import { uploadImage } from "../../../firebase/api/gallery/uploadImage";
import { deleteImage } from "../../../firebase/api/gallery/deleteImage";
import Loader from "../../../components/Loader/Loader";
import {
  addImages,
  ImagesPayloadType,
} from "../../../firebase/api/gallery/addImages";

const AddImages: FC = () => {
  const [addToGroupSelectOptions, setAddToGroupSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [payload, setPayload] = useState<ImagesPayloadType>({});
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    undefined
  );
  const [areImagesLoading, setAreImagesLoading] = useState<boolean>(false);

  const onImagesUpload = async (files: FileList) => {
    setUploadedImages([...Array.from(files), ...uploadedImages]);
    setAreImagesLoading(true);

    Array.from(files).map(async (file) => {
      return await uploadImage(file, (result) => {
        const { uuid, url } = result;
        setPayload((prev) => ({
          ...prev,
          ...{ [file.name]: { uuid, url, file } },
        }));
      });
    });

    setAreImagesLoading(false);
  };

  const onAddToGroupSelectChange = (option: SelectOption) => {
    if (option.id === "default") setSelectedGroup(undefined);
    else {
      setSelectedGroup(option.id);
    }
  };

  const onImageRemove = async (file: File) => {
    const payloadDataset = Object.values(payload).find(
      (set) => set.file?.name === file?.name
    );

    if (!payloadDataset) return;
    setAreImagesLoading(true);
    await deleteImage(payloadDataset.uuid!, () => {
      setUploadedImages((prev) =>
        prev.filter(
          (image) => image.name !== file.name && image.size !== file.size
        )
      );
    });
    setAreImagesLoading(false);
  };

  const onAddImageItemTagsChange = (file: File, tags: string[]) => {
    setPayload((prev) => {
      return { ...prev, [file.name]: { ...prev[file.name], tags } };
    });
  };

  const onAddImageItemsIsHighlightedChange = (file: File, status: boolean) => {
    setPayload((prev) => {
      return {
        ...prev,
        [file.name]: { ...prev[file.name], isHighlighted: status },
      };
    });
  };

  useEffect(() => {
    (async () => {
      if (addToGroupSelectOptions.length > 1) return;

      try {
        const groups = await getGroups();

        setAddToGroupSelectOptions(() => [
          { id: "default", label: "Nie dodawaj do grupy" },
          ...groups.map((group) => ({
            id: group.groupId,
            label: group.groupName || "Nieznana grupa",
          })),
        ]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const tags = await getCategories();
        setCategories(tags);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const uploadImagesData = async () => {
    setAreImagesLoading(true);
    const result = await addImages(payload, selectedGroup);

    if (result.status === "error") {
      console.log(result);
      return;
    }

    setPayload({});
    setUploadedImages([]);
    setSelectedGroup(undefined);

    setAreImagesLoading(false);
  };

  return (
    <section id="panel-add-image-view" className="panel-view">
      <main className="content f-column">
        <div className="actions-bar flex y-center x-between">
          <ComebackButton />

          {uploadedImages.length > 0 && (
            <PanelButton
              text="Wyślij"
              action="callback"
              callback={uploadImagesData}
            />
          )}
        </div>

        {areImagesLoading && <Loader />}

        <div className="content-wrapper f-column">
          <header>Dodaj zdjęcia</header>

          <div className="add-images-dropzone-wrapper">
            <ImageUploadBox onUpload={onImagesUpload} />
          </div>

          <div className="select-wrapper">
            <Select
              label="Dodaj do grupy"
              name="add-to-group"
              options={addToGroupSelectOptions}
              onChange={onAddToGroupSelectChange}
            />
          </div>

          <div className="images-count">
            <span>Dodano ({uploadedImages.length || 0})</span>
          </div>

          <div className="uploaded-image-items-wrapper">
            <AnimatePresence mode="popLayout">
              {uploadedImages.map((image) => (
                <AddImageItem
                  file={image}
                  categories={categories}
                  onRemove={() => onImageRemove(image)}
                  onTagsChange={(tags) => onAddImageItemTagsChange(image, tags)}
                  onIsHighlightedChange={(status) =>
                    onAddImageItemsIsHighlightedChange(image, status)
                  }
                  key={image.name}
                />
              ))}
            </AnimatePresence>
          </div>
        </div>
      </main>
    </section>
  );
};

export default AddImages;
