import { Filters, getGallery } from "./getGallery";
import { getGroups } from "./getGroups";
import GalleryGroup from "../../../interfaces/GalleryGroup";
import GalleryItem from "../../../interfaces/GalleryItem";

export interface GalleryGroupItem extends GalleryGroup {
  images: string[];
}

export type GroupsGalleryData = { [key: string]: GalleryGroupItem };

export const getGroupsGallery = async (
  filters: Filters
): Promise<GroupsGalleryData> => {
  const gallery: GalleryItem[] = await getGallery(filters);
  const groups: GalleryGroup[] = await getGroups();

  const g: string[] = [];
  const mergedGroups: string[] = gallery.map(
    (item) => item.groupId || "unknown"
  );
  for (let item of mergedGroups) {
    if (!g.includes(item)) g.push(item);
  }

  const galleryGroupItems: GroupsGalleryData = {};
  for (let id of g) {
    if (!id) continue;

    // @ts-ignore
    galleryGroupItems[id] = {
      ...groups.find((item) => item.groupId === id),
      images: gallery
        .filter((item) => item.groupId === id)
        .map((item) => item.url),
    };
  }

  return galleryGroupItems;
};
