import React, { FC } from "react";
import { ReactComponent as Dots } from "../../assets/svg/dots.svg";
import { CONTACT_INFO } from "../../data";
import { CustomEase1, FadeUpVariants } from "../../config/variants";
import { motion } from "framer-motion";
import { RevealVariants } from "./Variants";

const Contact: FC = () => {
  return (
    <section id="contact-view">
      <Dots className="dots" />
      <main className="content f-column y-start x-around">
        <div className="header-content f-column y-start x-start">
          <motion.header
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Odmień swoje <span>wnętrze</span>
          </motion.header>

          <motion.p
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.7,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Planujesz remont? Masz pytania? Skontaktuj się z nami, a chętnie
            odpowiemy na nie. Preferujemy kontakt telefoniczny.
          </motion.p>
        </div>

        <div className="contact-info flex f-column">
          <div className="contact-boxes f-column y-start x-start">
            <div className="box">
              <small>
                <motion.small
                  variants={RevealVariants}
                  initial="initial"
                  whileInView="reveal"
                  transition={{ ease: CustomEase1, duration: 0.75, delay: 1 }}
                  viewport={{ once: true }}
                >
                  Numer telefonu
                </motion.small>
              </small>
              <span>
                <motion.a
                  variants={RevealVariants}
                  initial="initial"
                  whileInView="reveal"
                  transition={{
                    ease: CustomEase1,
                    duration: 0.75,
                    delay: 1.25,
                  }}
                  viewport={{ once: true }}
                  href={`tel:${CONTACT_INFO.phone}`}
                >
                  {CONTACT_INFO.phone}
                </motion.a>
              </span>
            </div>

            <div className="box">
              <small>
                <motion.small
                  variants={RevealVariants}
                  initial="initial"
                  whileInView="reveal"
                  transition={{ ease: CustomEase1, duration: 0.75, delay: 1 }}
                  viewport={{ once: true }}
                >
                  Adres e-mail
                </motion.small>
              </small>
              <span>
                <motion.a
                  variants={RevealVariants}
                  initial="initial"
                  whileInView="reveal"
                  viewport={{ once: true }}
                  transition={{
                    ease: CustomEase1,
                    duration: 0.75,
                    delay: 1.25,
                  }}
                  href={`mailto:${CONTACT_INFO.email}`}
                >
                  {CONTACT_INFO.email}
                </motion.a>
              </span>
            </div>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1924.5797519569792!2d20.74654439727239!3d50.63282660538498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4717eda05f7cb1bb%3A0x1dc8f4abe17a03d4!2s26-020%20Suchowola!5e0!3m2!1sen!2spl!4v1702323266508!5m2!1sen!2spl"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
    </section>
  );
};

export default Contact;
