import React, { FC, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import GalleryItemTile from "../GalleryItemTile/GalleryItemTile";
import SkeletonGalleryItemTile from "../skeleton/SkeletonGalleryItemTile/SkeletonGalleryItemTile";
import GalleryItem from "../../interfaces/GalleryItem";
import FullscreenImageView from "../FullscreenImageView/FullscreenImageView";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

interface GalleryWrapperProps {
  isLoading: boolean;
  gallery: GalleryItem[] | undefined;
  imagesCount: number;
}

const GalleryWrapper: FC<GalleryWrapperProps> = ({
  isLoading,
  gallery,
  imagesCount,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFullscreenViewVisible, setIsFullscreenViewVisible] =
    useState<boolean>(false);
  const [clickedGalleryItem, setClickedGalleryItem] =
    useState<GalleryItem | null>(null);

  const showFullscreenView = (item: GalleryItem | undefined): void => {
    if (!item) return;

    setIsFullscreenViewVisible(true);
    setClickedGalleryItem(item);
  };

  const onFullscreenViewHide = (): void => {
    setIsFullscreenViewVisible(false);
    setClickedGalleryItem(null);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "grid",
      imageId: "",
    }));
  };

  const onGalleryItemClick = (item: GalleryItem): void => {
    showFullscreenView(item);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "fullscreen",
      imageId: item.id,
    }));
  };

  useEffect(() => {
    const view = searchParams.get("view") || "grid";
    if (view === "fullscreen" && gallery) {
      const imageId: string | null = searchParams.get("imageId") || null;

      if (imageId) {
        showFullscreenView(gallery.find((item) => item.id === imageId));
      }
    }
  }, [searchParams.get("view"), searchParams.get("imageId")]);

  return (
    <>
      <div className="gallery-content-wrapper f-column y-start x-start">
        <div className="gallery-content-bar">
          {!isLoading ? (
            <span>Wyniki ({imagesCount || 0})</span>
          ) : (
            <Skeleton width={100} />
          )}
        </div>
        <AnimatePresence mode="popLayout">
          <div
            className="gallery-wrapper"
            data-safari={
              navigator.userAgent.toLowerCase().indexOf("safari") !== -1
            }
          >
            {!isLoading && gallery
              ? gallery?.map((item) => (
                  <GalleryItemTile
                    data={item}
                    onClick={onGalleryItemClick}
                    key={item.id}
                    groupLink={
                      item?.groupId
                        ? `/galeria/grupy/${item.groupId}?imageId=${item.id}`
                        : undefined
                    }
                  />
                ))
              : Array(8)
                  .fill(2)
                  .map(Math.pow)
                  .map((s) => <SkeletonGalleryItemTile key={s} />)}
          </div>
        </AnimatePresence>
      </div>

      <FullscreenImageView
        current={clickedGalleryItem}
        isVisible={isFullscreenViewVisible}
        onHide={onFullscreenViewHide}
        gallery={gallery ? gallery : []}
      />
    </>
  );
};

export default GalleryWrapper;
