import GalleryItem from "../../../interfaces/GalleryItem";
import { doc, updateDoc } from "firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../../config";
import { db } from "../../init";

export const deleteImages = async (items: GalleryItem[]): Promise<void[]> => {
  if (!items) return [];

  return Promise.all(
    items.map(async (item) => {
      return await updateDoc(doc(db, FIRESTORE_COLLECTIONS.GALLERY, item.id), {
        isArchived: true,
      });
    })
  );
};
