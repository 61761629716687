import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getGroup } from "../../firebase/api/gallery/getGroup";
import GalleryGroup from "../../interfaces/GalleryGroup";
import Skeleton from "react-loading-skeleton";
import GalleryItem from "../../interfaces/GalleryItem";
import { Filters, getGallery } from "../../firebase/api/gallery/getGallery";
import GalleryWrapper from "../../components/GalleryWrapper/GalleryWrapper";
import { motion } from "framer-motion";
import GalleryFilters from "../../components/GalleryFilters/GalleryFilters";
import { getCategories } from "../../firebase/api/gallery/getCategories";
import { ReactComponent as Marker } from "../../assets/svg/marker.svg";
import Button from "../../components/Button/Button";

const GGroup: FC = () => {
  const [searchParams] = useSearchParams();
  const { groupId } = useParams<{ groupId: string }>();

  const [group, setGroup] = useState<GalleryGroup | null>(null);
  const [isGalleryLoading, setIsGalleryLoading] = useState<boolean>(true);
  const [isGroupLoading, setIsGroupLoading] = useState<boolean>(true);
  const [gallery, setGallery] = useState<GalleryItem[] | undefined>(undefined);
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (!groupId) return;

    (async () => {
      const g = await getGroup(groupId);
      const t = await getCategories(groupId);
      setGroup(g);
      setTags(t);
      setIsGroupLoading(false);
    })();
  }, []);

  useEffect(() => {
    const searchTags = searchParams.get("tags");
    setActiveTags(searchTags ? searchTags.split(",") : []);

    const filters: Filters = {
      sort: searchParams.get("sort") || undefined,
      tags: searchTags ? searchTags.split(",") : [],
      groupId: groupId,
    };

    (async () => {
      const g = await getGallery(filters);
      setGallery(g.filter((item) => !item?.isArchived));
      setIsGalleryLoading(false);
    })();
  }, [searchParams.get("tags"), searchParams.get("sort")]);

  return (
    <section id="gallery-group">
      <main className="content f-column y-start x-start">
        <motion.span
          className="main-site-text"
          animate={{
            opacity: 0.1,
            transition: { duration: 1, type: "easeOut", delay: 0.3 },
          }}
          initial={{ opacity: 0 }}
        >
          Galeria
        </motion.span>
        {groupId ? (
          <>
            <div className="header-content f-column y-start x-start">
              {isGroupLoading ? (
                <>
                  <Skeleton width={300} height={50} />
                  <Skeleton width={370} height={10} count={7} />
                </>
              ) : (
                group && (
                  <>
                    <header>{group?.groupName}</header>
                    <p>{group?.groupDescription}</p>
                    <div className="localization flex y-center">
                      <Marker
                        fill="var(--primary-color)"
                        width={24}
                        height={24}
                      />
                      <span>{group?.localization}</span>
                    </div>
                  </>
                )
              )}
            </div>

            <GalleryFilters tags={tags} activeTags={activeTags} />
            <GalleryWrapper
              isLoading={isGalleryLoading}
              gallery={gallery}
              imagesCount={gallery?.length || 0}
            />

            <div className="groups-btn-wrapper">
              <Button
                text="Zobacz inne grupy"
                action="link"
                path="/galeria/grupy"
              />
            </div>
          </>
        ) : (
          <div className="error">Taka grupa niestety nie istnieje</div>
        )}
      </main>
    </section>
  );
};

export default GGroup;
