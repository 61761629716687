import { motion, Variants } from "framer-motion";
import React, { FC, useState } from "react";
import GalleryItem from "../../interfaces/GalleryItem";
import { ReactComponent as DashArrowIcon } from "../../assets/svg/dash-arrow.svg";
import GalleryTag from "../GalleryTag/GalleryTag";

export const GALLERY_ITEM_LOCALE_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "long",
  year: "numeric",
};

export const ThumbnailVariants: Variants = {
  blur: {
    filter: "blur(20px)",
  },

  noBlur: {
    filter: "blur(0)",
    transition: {
      duration: 0.2,
      type: "easeOut",
    },
  },
};

export const ImageVariants: Variants = {
  hidden: { opacity: 0 },
  shown: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "easeOut",
    },
  },
};

interface GalleryItemTileProps {
  data: GalleryItem;
  onClick: (item: GalleryItem) => void;
  groupLink?: string;
  isSelecting?: boolean;
  onSelect?: (status: boolean) => void;
}

const GalleryItemTile: FC<GalleryItemTileProps> = ({
  data,
  onClick,
  groupLink,
  isSelecting,
  onSelect,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);

  const onLoad = (): void => {
    setLoaded(true);
  };

  const onGroupRedirectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const onSelectClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    if (onSelect) onSelect(!selected);
    setSelected(!selected);
  };

  return (
    <motion.div
      className="gallery-item-tile"
      layout
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={() => onClick(data)}
    >
      <motion.div
        className="gallery-item-thumbnail"
        style={{ backgroundImage: `url(${data.thumbnail_url})` }}
        variants={ThumbnailVariants}
        initial="blur"
        animate={loaded ? "noBlur" : "blur"}
      >
        <motion.img
          src={data.url}
          alt={data.id}
          variants={ImageVariants}
          initial="hidden"
          animate={loaded ? "shown" : "hidden"}
          loading="lazy"
          decoding="async"
          onLoad={onLoad}
        />
      </motion.div>

      <div className="gallery-item-content-overlay flex y-center x-between f-column">
        <div className="overlay-content-wrapper flex y-center x-start">
          {data.tags?.map((tag) => (
            <GalleryTag text={tag} isActive={true} size="small" key={tag} />
          ))}
        </div>
        <div className="overlay-content-wrapper flex y-center x-between">
          <span>
            {data.createdAt
              .toDate()
              .toLocaleDateString("pl-PL", GALLERY_ITEM_LOCALE_DATE_OPTIONS)}
          </span>
          {groupLink && (
            <a
              href={groupLink}
              className="group-redirect"
              onClick={onGroupRedirectClick}
            >
              <DashArrowIcon />
            </a>
          )}
        </div>
      </div>

      {isSelecting && (
        <div
          className="select-overlay"
          onClick={onSelectClick}
          data-selected={selected}
        >
          <div className="select"></div>
        </div>
      )}
    </motion.div>
  );
};

export default GalleryItemTile;
