import { Variants } from "framer-motion";

export const RevealVariants: Variants = {
  initial: {
    y: "100%",
  },

  reveal: {
    y: 0,
  },
};
