import {
  collection,
  query,
  orderBy,
  getDocs,
  where,
  QueryOrderByConstraint,
  QueryFieldFilterConstraint,
} from "firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../../config";
import { db } from "../../init";
import GalleryItem from "../../../interfaces/GalleryItem";

export type Filters = {
  sort?: "newest" | "oldest" | "highlighted" | string;
  tags?: string[];
  groupId?: string;
};

export const getGallery = async (
  filters: Filters,
  deleted: boolean = false
): Promise<GalleryItem[]> => {
  const gallery = collection(db, FIRESTORE_COLLECTIONS.GALLERY);

  const orderByStatements: { [key: string]: QueryOrderByConstraint } = {
    newest: orderBy("createdAt", "desc"),
    oldest: orderBy("createdAt", "asc"),
    default: orderBy("createdAt"),
  };

  const whereStatements: {
    [key: string]: QueryFieldFilterConstraint | QueryFieldFilterConstraint[];
  } = {
    tags: (filters?.tags || []).map((tag) => where(`tags.${tag}`, "==", true)),
    isHighlighted: where("isHighlighted", "==", filters.sort === "highlighted"),
    byGroup: where("groupId", "==", filters.groupId),
  };

  const getOrderByStatement = (): QueryOrderByConstraint => {
    type keys = keyof typeof orderByStatements;
    const dateSortBy: keys = filters?.sort || "default";
    return orderByStatements[dateSortBy];
  };

  const getWhereStatement = (): QueryFieldFilterConstraint[] => {
    const f: QueryFieldFilterConstraint[] = [];

    if ((filters?.tags || []).length > 0) {
      f.push(...(whereStatements.tags as QueryFieldFilterConstraint[]));
    }

    if (filters?.sort === "highlighted") {
      f.push(whereStatements.isHighlighted as QueryFieldFilterConstraint);
    }

    if (filters?.groupId) {
      f.push(whereStatements.byGroup as QueryFieldFilterConstraint);
    }

    return f;
  };

  const q = query(gallery, getOrderByStatement(), ...getWhereStatement());

  return (await getDocs(q))?.docs?.map((doc) => {
    if (doc.exists()) {
      return {
        id: doc.id,
        ...doc.data(),
        tags: Object.keys(doc.data()?.tags),
      };
    }
  }) as unknown as GalleryItem[];
};
