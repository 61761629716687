import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../init";
import { STORAGE_FOLDERS } from "../../config";
import uuid4 from "uuid4";

export type UploadResultType = {
  status: "success" | "error";
  error?: null | string;
  result?: { [key: string]: any };
};

export const uploadImage = async (
  image: File,
  successCallback: (result: { uuid: string; url: string }) => void
): Promise<UploadResultType> => {
  if (!image) return { status: "error", error: "Zdjęcie nie istnieje" };

  const result: UploadResultType = {
    status: "success",
    result: {},
  };

  const uuid = uuid4();
  const galleryStorageRef = ref(storage, STORAGE_FOLDERS.GALLERY + "/" + uuid);

  await uploadBytes(galleryStorageRef, image, {
    contentType: image.type,
  })
    .then((snapshot) => {
      getDownloadURL(snapshot.ref)
        .then((url) => {
          result.result! = { url, uuid };
          successCallback({ url, uuid });
        })
        .catch((error) => {
          result.status = "error";
          result.error = "Wystąpił błąd podczas pobierania adresu url zdjęcia";
        });
    })
    .catch((error) => {
      result.status = "error";
      result.error = "Wystąpił błąd podczas wysyłania zdjęcia";
    });

  return result;
};
