export const FIRESTORE_COLLECTIONS = {
  GALLERY: "gallery",
  GALLERY_CATEGORIES: "gallery_categories",
  GALLERY_GROUPS: "gallery_groups",
};

export const STORAGE_FOLDERS = {
  GALLERY: "gallery",
};

export const GALLERY_CATEGORIES_ID: string = "Go7nHPjGusnrICRz9691";
