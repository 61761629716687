import React, { FC, useEffect, useRef } from "react";
import { motion, useInView, Variants } from "framer-motion";

interface LandingOfferItemProps {
  text: string;
  description: string;
  setActiveId: React.Dispatch<number | null>;
  id: number;
}

const LandingOfferItem: FC<LandingOfferItemProps> = ({
  text,
  description,
  setActiveId,
  id,
}) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const isInView = useInView(ref, { margin: "-50% 0px -50% 0px" });

  useEffect(() => {
    if (isInView) {
      setActiveId(id);
    }
  }, [isInView]);

  const TitleVariants: Variants = {
    initial: {
      opacity: 0.7,
      color: "#d3d3d3",
      transition: {
        duration: 0.1,
        ease: "easeOut",
      },
    },
    inView: {
      opacity: 1,
      color: "var(--primary-color)",
      transition: {
        duration: 0.1,
        ease: "easeOut",
      },
    },

    onScreen: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const DescriptionVariants: Variants = {
    initial: {
      opacity: 0,
      x: -50,
    },
    inView: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        opacity: {
          duration: 0.2,
          ease: "easeOut",
        },
      },
    },
    exit: {
      opacity: 0,
      x: -50,
      transition: {
        duration: 0.3,
        opacity: {
          duration: 0.2,
          ease: "easeOut",
        },
      },
    },
  };

  return (
    <a
      href={"/oferta?id=" + text.toLowerCase().replaceAll(" ", "-")}
      className="landing-offer-item flex f-column y-start x-start"
      ref={ref}
    >
      <motion.span
        variants={TitleVariants}
        initial="initial"
        animate={isInView ? "inView" : "initial"}
        whileInView="onScreen"
        viewport={{ once: true, margin: "-50% 0px 0px 0px" }}
      >
        {text}
      </motion.span>
      <motion.p
        variants={DescriptionVariants}
        initial="initial"
        animate={isInView ? "inView" : "exit"}
        exit="exit"
      >
        {description}
      </motion.p>
    </a>
  );
};

export default LandingOfferItem;
