import GalleryGroup from "../../../interfaces/GalleryGroup";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../init";
import { FIRESTORE_COLLECTIONS } from "../../config";

export const getGroups = async (): Promise<GalleryGroup[]> => {
  const gallery_groups = collection(db, FIRESTORE_COLLECTIONS.GALLERY_GROUPS);
  const q = query(gallery_groups, orderBy("groupName"));

  return (await getDocs(q))?.docs?.map((doc) => {
    if (doc.exists()) {
      return {
        ...doc.data(),
        groupId: doc.id,
      };
    }
  }) as unknown as GalleryGroup[];
};
