import React, { FC } from "react";
import SmallLoader from "../SmallLoader/SmallLoader";

interface SubmitButtonProps {
  isLoading?: boolean;
  text: string;
}

const SubmitButton: FC<SubmitButtonProps> = ({ isLoading, text }) => {
  return (
    <div className="submit-button-wrapper">
      <button type="submit">
        {isLoading ? <SmallLoader /> : <span>{text}</span>}
      </button>
    </div>
  );
};

export default SubmitButton;
