import React, { FC, useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from "../../config";

interface ImageUploadBoxProps {
  onUpload: (files: FileList) => void;
  currentFiles?: string[];
  error?: string | null;
}

const ImageUploadBox: FC<ImageUploadBoxProps> = ({
  onUpload,
  error,
  currentFiles,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedImages, setUploadedImages] = useState<string[] | undefined>(
    undefined
  );
  const [imageError, setImageError] = useState<null | string | undefined>(null);

  useEffect(() => {
    if (currentFiles) setUploadedImages(currentFiles);
    setImageError(error);
  }, [currentFiles, error]);

  const onImageUploadClick = (): void => {
    inputRef.current!.click();
  };

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setImageError(null);
    setUploadedImages(undefined);

    const files: FileList | null = e.currentTarget.files;

    if (!files) {
      setImageError("Wystąpił błąd podczas dodawania zdjęć.");
      return;
    }

    Array.from(files).map((file) => {
      if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
        setImageError(
          `Rozszerzenie zdjęcia ${file.name} jest nie poprawne (${
            file.name.split(".")[file.name.split(".").length - 1]
          })`
        );

        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setImageError(
          `Waga zdjęcia ${file.name} jest za duża (${(
            file.size /
            1000 /
            1000
          ).toFixed(1)}MB).`
        );

        return;
      }

      if (uploadedImages) {
        setUploadedImages([URL.createObjectURL(file), ...uploadedImages]);
      }
    });

    onUpload(files);
  };

  return (
    <div className="upload-image-wrapper flex">
      <div className="info f-column y-start">
        <span className="label">Prześlij zdjęcia (.png, max. 4MB)</span>
        <Button
          text="Dodaj zdjęcia"
          action="callback"
          callback={onImageUploadClick}
        />
      </div>

      <input
        type="file"
        className="input"
        onChange={(e) => onFilesChange(e)}
        accept={ACCEPTED_FILE_TYPES.join(", ")}
        multiple
        size={MAX_FILE_SIZE}
        ref={inputRef}
      />

      <div className="image-wrapper f-column y-start x-start">
        <div className="uploaded-images">
          {uploadedImages &&
            uploadedImages.map((uploadedImage, i) => (
              <img
                key={"uimage-" + i}
                src={uploadedImage}
                alt="Uploaded image"
              />
            ))}
        </div>

        {imageError && <small className="image-error">{imageError}</small>}
      </div>
    </div>
  );
};

export default ImageUploadBox;
