import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";

interface ComebackButtonProps {
  path?: string;
  label?: string;
}

const ComebackButton: FC<ComebackButtonProps> = ({ path, label = "Wróć" }) => {
  const navigate = useNavigate();

  const onComebackClick = (): void => {
    if (path) navigate(path);
    else navigate(-1);
  };

  return (
    <span className="comeback flex y-center" onClick={onComebackClick}>
      <ArrowIcon width={24} height={24} />
      {label}
    </span>
  );
};

export default ComebackButton;
