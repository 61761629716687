import React, { FC, useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";
import { motion, useCycle, Variants } from "framer-motion";

interface SelectProps {
  label: string;
  name: string;
  options: SelectOption[];
  onChange: (option: SelectOption) => void;
  activeId?: string;
}

export interface SelectOption {
  id: string;
  label: string;
  isDefault?: boolean;
  disabled?: boolean;
}

const Select: FC<SelectProps> = ({
  label,
  name,
  options,
  onChange,
  activeId,
}) => {
  const [active, setActive] = useState<SelectOption | null>(null);
  const [isOpened, cycleIsOpened] = useCycle(false, true);

  useEffect(() => {
    if (activeId) {
      const a = options.find((option) => option.id === activeId) || null;
      if (a) {
        setActive(a);
        return;
      }
    }

    const defaultValue = options.find((option) => option.isDefault);
    if (defaultValue && active === null) {
      setActive(defaultValue);
    }
  }, []);

  const DropdownVariants: Variants = {
    initial: {
      y: "-.5rem",
      opacity: 0,
      display: "none",
      transition: {
        when: "afterChildren",
        duration: 0.1,
        type: "ease",
        staggerDirection: -1,
        delayChildren: 0.1,
        display: {
          duration: 0,
          when: "afterend",
        },
      },
    },
    shown: {
      y: 0,
      opacity: 1,
      display: "block",
      transition: {
        type: "ease",
        duration: 0.1,
        staggerChildren: 0.1,
        delayChildren: 0.15,
        display: {
          duration: 0,
          when: "beforebegin",
        },
      },
    },
  };

  const DropdownOptionVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
      transition: {
        duration: 0.1,
        type: "easeOut",
      },
    },
    shown: {
      x: [-5, 3, 0],
      opacity: 1,
      transition: {
        type: "easeOut",
        duration: 0.3,
        opacity: {
          duration: 0.1,
        },
      },
    },
  };

  const onSelectClick = (): void => {
    cycleIsOpened();
  };

  const onOptionClick = (option: SelectOption): void => {
    if (option.id === active?.id || option.disabled) return;

    onChange(option);
    setActive(option);
    cycleIsOpened();
  };

  return (
    <div className="select flex f-column y-center x-center">
      <div
        className="select-overlay"
        data-active={isOpened}
        onClick={onSelectClick}
      ></div>
      <button type="button" className="main-face" onClick={onSelectClick}>
        <span>{active ? active.label : label}</span>
        <ArrowIcon width={24} height={24} />
      </button>

      <motion.div
        className="select-options"
        variants={DropdownVariants}
        animate={isOpened ? "shown" : "initial"}
        initial="initial"
      >
        {options?.map((option) => (
          <div
            className="select-option"
            key={option.id}
            onClick={() => onOptionClick(option)}
            data-disabled={option?.disabled}
            data-active={active?.id === option.id}
          >
            <motion.label htmlFor={option.id} variants={DropdownOptionVariants}>
              {option.label}
            </motion.label>
            <input
              type="radio"
              className="select-option"
              id={option.id}
              name={name}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Select;
