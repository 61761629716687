import React, { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";
import { AnimatePresence, motion, Variants } from "framer-motion";

export interface ModalProps {
  children?: React.ReactNode;
  onClose: () => void;
  visible?: boolean;
  id?: string;
}

const Modal: FC<ModalProps> = ({ children, onClose, visible, id }) => {
  const modal = document.getElementById("modal");
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible !== undefined) setIsVisible(visible);
  }, [visible]);

  const closeModal = () => {
    setIsVisible(false);
    onClose();
  };

  const onOverlayClick = () => {
    closeModal();
  };

  const onCrossClick = () => {
    closeModal();
  };

  const ModalVariants: Variants = {
    initial: { y: "120%", opacity: 0, skewY: "35deg" },
    animate: {
      y: 0,
      opacity: 1,
      skewY: "0deg",
      transition: {
        duration: 0.4,
        ease: "easeOut",
        y: {
          duration: 0.3,
        },
        skewY: {
          duration: 0.4,
        },
      },
    },
    exit: { opacity: 0, transition: { duration: 0.2 } },
  };

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <>
          <motion.div
            className="modal flex f-column"
            key="modal"
            id={id}
            variants={ModalVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <div className="modal-top-bar flex y-center x-end">
              <CrossIcon width={32} height={32} onClick={onCrossClick} />
            </div>

            <div className="modal-body">{children}</div>
          </motion.div>
          <div className="modal-overlay" onClick={onOverlayClick}></div>
        </>
      )}
    </AnimatePresence>,
    modal!
  );
};

export default Modal;
