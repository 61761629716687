import React, { FC, useLayoutEffect, useState } from "react";
import { PANEL_NAV_LINKS } from "../../config";
import {
  NavLink as NavLinkElement,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NavLink from "../../interfaces/NavLink";
import useAuth from "../../hooks/useAuth";

const PanelSidebar: FC = () => {
  const { auth, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLinkId, setActiveLinkId] = useState<number | null>(null);

  useLayoutEffect(() => {
    const current = PANEL_NAV_LINKS.find((link) =>
      link.link.startsWith(location.pathname.split("/").slice(0, 4).join("/"))
    );

    if (current) {
      setActiveLinkId(current.id);
    } else {
      setActiveLinkId(PANEL_NAV_LINKS[0].id);
    }
  }, [location.pathname]);

  const onLinkClick = (link: NavLink) => {
    setActiveLinkId(link.id);
  };

  const onLogoutClick = async (): Promise<void> => {
    await logout(() => {
      navigate("/admin/panel/login", { replace: true });
    });
  };

  return (
    <nav role="navigation" id="panel-sidebar" className="flex y-start x-end">
      <div className="sidebar-content f-column x-center y-start">
        <header className="greeting">
          Witaj,{" "}
          {auth?.displayName === "Anonymous" ? "Admin" : auth.displayName}
        </header>

        <ul className="sidebar-navlinks f-column">
          {PANEL_NAV_LINKS.map((link) => (
            <NavLinkElement
              to={link.link}
              data-active={activeLinkId === link.id}
              onClick={() => onLinkClick(link)}
              key={link.id}
            >
              <li>
                {link.icon && (
                  <link.icon width={24} height={24} data-id={link.id} />
                )}{" "}
                {link.name}
              </li>
            </NavLinkElement>
          ))}

          <li className="log-out" onClick={onLogoutClick}>
            Wyloguj się
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default PanelSidebar;
