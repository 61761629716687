import GalleryGroup from "../../../interfaces/GalleryGroup";
import { doc, getDoc } from "firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../../config";
import { db } from "../../init";

export const getGroup = async (groupId: string): Promise<GalleryGroup> => {
  const def: GalleryGroup = {
    groupId: groupId,
    groupName: null,
    groupDescription: null,
    localization: null,
    coverImageThumbnail: null,
    coverImageUrl: null,
  };

  if (!groupId || groupId === "") return def;

  const gallery_group_doc = await getDoc(
    doc(db, FIRESTORE_COLLECTIONS.GALLERY_GROUPS, groupId)
  );

  if (gallery_group_doc?.exists()) {
    return {
      ...gallery_group_doc.data(),
      groupId: gallery_group_doc.id,
    } as unknown as GalleryGroup;
  }

  return def;
};
