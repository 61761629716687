import React, { FC } from "react";
import Button, { ButtonProps } from "../Button/Button";

interface PanelButtonProps extends ButtonProps {}

const PanelButton: FC<PanelButtonProps> = ({ ...props }) => {
  return <Button {...props} filled color="primary" isPanelButton />;
};

export default PanelButton;
