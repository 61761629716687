import { motion } from "framer-motion";
import { FC, useEffect, useState } from "react";

interface SwitchProps {
  on?: boolean;
  onChange?: (status: boolean) => void;
  disabled?: boolean;
}

const Switch: FC<SwitchProps> = ({ on, onChange, disabled }) => {
  const [isOn, setIsOn] = useState<boolean>(false);

  useEffect(() => {
    setIsOn(on || false);
  }, [on]);

  const onClick = () => {
    if (disabled) return;

    setIsOn((prev) => !prev);
    if (onChange) onChange(!isOn);
  };

  return (
    <div
      className="switch flex"
      data-is-on={isOn}
      data-disabled={disabled}
      onClick={onClick}
    >
      <motion.div
        className="handle"
        layout
        transition={{
          type: "spring",
          stiffness: 700,
          damping: 30,
        }}
      />
    </div>
  );
};

export default Switch;
