import { motion } from "framer-motion";
import React, { FC } from "react";
import { FadeRightVariants, FadeUpVariants } from "../../config/variants";

interface HeaderProps {
  title: string;
  subtitle: string;
  align?: "left" | "center" | "right";
}

const Header: FC<HeaderProps> = ({ title, subtitle, align = "left" }) => {
  const variants = align === "left" ? FadeRightVariants : FadeUpVariants;

  return (
    <div className="header-wrapper f-column" data-align={align}>
      <motion.header
        variants={variants}
        whileInView={align === "left" ? "fadeRight" : "fadeUp"}
        initial="initial"
        viewport={{ once: true }}
        transition={{
          duration: 0.3,
          ease: "easeOut",
        }}
      >
        {title}
      </motion.header>
      <motion.span
        variants={variants}
        whileInView={align === "left" ? "fadeRight" : "fadeUp"}
        initial="initial"
        viewport={{ once: true }}
        transition={{
          duration: 0.3,
          ease: "easeOut",
          delay: 0.3,
        }}
      >
        {subtitle}
      </motion.span>
    </div>
  );
};

export default Header;
