import { getDoc, doc } from "firebase/firestore";
import { db } from "../../init";
import { FIRESTORE_COLLECTIONS, GALLERY_CATEGORIES_ID } from "../../config";
import { getGallery } from "./getGallery";

export const getCategories = async (groupId?: string): Promise<string[]> => {
  if (groupId) {
    const items = await getGallery({
      groupId,
    });

    if (!items || items.length === 0) return [];

    const categories: string[] = [];
    const merged = items
      .map((item) => item.tags)
      .reduce((prev, curr) => [...prev, ...curr]);

    for (let item of merged) {
      if (!categories.includes(item)) categories.push(item);
    }

    return categories;
  } else {
    const category_doc = await getDoc(
      doc(db, FIRESTORE_COLLECTIONS.GALLERY_CATEGORIES, GALLERY_CATEGORIES_ID)
    );

    if (category_doc?.exists()) {
      return category_doc.data()?.categories || [];
    }
  }

  return [];
};
