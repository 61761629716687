import { auth } from "../../init";
import { signInWithEmailAndPassword, UserCredential } from "firebase/auth";

export interface LoginResult {
  error: null | string;
  status: "success" | "error" | null;
  user?: null | UserCredential;
}

export const signUp = async (credentials: {
  email: string;
  passwd: string;
}): Promise<LoginResult> => {
  const result: LoginResult = {
    error: null,
    status: null,
  };

  await signInWithEmailAndPassword(auth, credentials.email, credentials.passwd)
    .then((userCredential) => {
      result.status = "success";
      result.user = userCredential;
    })
    .catch((error) => {
      result.status = "error";
      const errorCode = error.code;

      switch (errorCode) {
        case "auth/invalid-email":
          {
            result.error = "Konto o takich danych nie istnieje";
          }
          break;
      }
    });

  return result;
};
