import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";
import Select, { SelectOption } from "../Select/Select";
import GalleryTag from "../GalleryTag/GalleryTag";
import Checkbox from "../Checkbox/Checkbox";

interface AddImageItemProps {
  file: File;
  onRemove: () => void;
  categories: string[];
  onTagsChange: (tags: string[]) => void;
  onIsHighlightedChange: (status: boolean) => void;
}

const AddImageItem: FC<AddImageItemProps> = ({
  file,
  onRemove,
  categories,
  onTagsChange,
  onIsHighlightedChange,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [tagSelectOptions, setTagSelectOptions] = useState<SelectOption[]>([]);

  const onTagAdd = (option: SelectOption) => {
    setSelectedTags((prev) => [option.label, ...prev]);
  };

  const onGalleryTagClick = (tag: string) => {
    setSelectedTags((prev) => prev.filter((t) => t !== tag));
  };

  const onCheckboxChange = (status: boolean) => {
    setIsHighlighted(status);
    onIsHighlightedChange(status);
  };

  useEffect(() => {
    setTagSelectOptions(
      categories
        .filter((category) => !selectedTags.includes(category))
        .map((category) => ({
          id: category,
          label: category,
        }))
    );
    onTagsChange(selectedTags);
  }, [selectedTags]);

  return (
    <motion.div
      className="add-image-item"
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="image flex">
        <img src={URL.createObjectURL(file)} alt={file.name} />
      </div>

      <div className="add-image-item-action-bar f-column">
        <div className="remove-icon-wrapper flex y-center x-start">
          <CrossIcon
            width={32}
            height={32}
            fill="var(--font-color)"
            onClick={onRemove}
          />

          <span>Usuń</span>
        </div>

        <AnimatePresence mode="popLayout">
          {selectedTags.length > 0 && (
            <div className="tags flex y-start x-start">
              {selectedTags?.map((tag) => (
                <GalleryTag
                  text={tag}
                  isActive
                  onClick={() => onGalleryTagClick(tag)}
                  key={tag}
                />
              ))}
            </div>
          )}
        </AnimatePresence>

        <Checkbox
          id="hihlighted"
          label="Wyróżnione"
          onChange={onCheckboxChange}
        />

        <div className="tags-select-wrapper">
          <Select
            label="Dodaj kategorie"
            name="categories"
            options={tagSelectOptions}
            onChange={onTagAdd}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default AddImageItem;
