import React, { FC } from "react";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";

const PanelUser: FC = () => {
  return (
    <div className="panel-user">
      <UserIcon width={24} height={24} />
    </div>
  );
};

export default PanelUser;
