import { motion } from "framer-motion";
import React, { FC, useState } from "react";
import { GalleryGroupItem } from "../../firebase/api/gallery/getGroupsGallery";
import {
  ImageVariants,
  ThumbnailVariants,
} from "../GalleryItemTile/GalleryItemTile";

interface GalleryGroupItemTileProps {
  data: GalleryGroupItem;
  isPanel?: boolean;
}

const GalleryGroupItemTile: FC<GalleryGroupItemTileProps> = ({
  data,
  isPanel,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const images = data?.images.slice(0, 3);

  const onLoad = (): void => {
    setLoaded(true);
  };

  return (
    <motion.a
      href={
        !isPanel
          ? `/galeria/grupy/${data.groupId}`
          : `/admin/panel/galeria/grupa/${data.groupId}`
      }
      className="gallery-group-item-tile f-column y-center x-center"
      layout
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      data-panel={isPanel}
    >
      <motion.div
        className="images-stack-thumbnail"
        style={{ backgroundImage: `url(${data.coverImageThumbnail})` }}
        variants={ThumbnailVariants}
        initial="blur"
        animate={loaded ? "noBlur" : "blur"}
      >
        <motion.img
          src={data.coverImageUrl || ""}
          alt={data.groupName || "Czołowe zdjęcie z grupy"}
          className="cover-image"
          variants={ImageVariants}
          initial="hidden"
          animate={loaded ? "shown" : "hidden"}
          loading="lazy"
          onLoad={onLoad}
        />

        {images &&
          images?.map((image, i) => (
            <img
              src={image}
              alt={data.groupName || "Zdjęcie z grupy"}
              className="stack-image"
              data-index={i}
              key={image}
              loading="lazy"
            />
          ))}
      </motion.div>

      <div className="group-item-text-content flex y-center x-between">
        <span className="group-name">{data.groupName}</span>
        <span className="group-localization">{data.localization}</span>
      </div>
    </motion.a>
  );
};

export default GalleryGroupItemTile;
