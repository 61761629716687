import React, { FC } from "react";
import Button from "../Button/Button";

const ContactUsBlock: FC = () => {
  return (
    <div className="contact-us-block f-column y-start x-start">
      <span>
        Wybierz <span>nas</span>
      </span>

      <p>
        Wspólnie przekształcimy Twoje pomieszczenia w miejsce, o którym zawsze
        marzyłeś/aś.
      </p>

      <Button text="Zróbmy to" action="link" path="/kontakt" />
    </div>
  );
};

export default ContactUsBlock;
