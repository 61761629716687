import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { CustomEase1, FadeUpVariants } from "../../config/variants";

import { ReactComponent as Dots } from "../../assets/svg/dots.svg";
import {
  getGroupsGallery,
  GroupsGalleryData,
} from "../../firebase/api/gallery/getGroupsGallery";
import GalleryFilters from "../../components/GalleryFilters/GalleryFilters";
import { useSearchParams } from "react-router-dom";
import { Filters } from "../../firebase/api/gallery/getGallery";
import { getCategories } from "../../firebase/api/gallery/getCategories";
import GroupGalleryWrapper from "../../components/GroupGalleryWrapper/GroupGalleryWrapper";

const GroupsGallery: FC = () => {
  const [searchParams] = useSearchParams();

  const [tags, setTags] = useState<string[]>([]);
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [isGalleryLoading, setIsGalleryLoading] = useState<boolean>(true);
  const [galleryGroupsItems, setGalleryGroupsItems] =
    useState<GroupsGalleryData | null>(null);

  useEffect(() => {
    const searchTags = searchParams.get("tags");
    setActiveTags(searchTags ? searchTags.split(",") : []);

    const filters: Filters = {
      sort: searchParams.get("sort") || undefined,
      tags: searchTags ? searchTags.split(",") : [],
    };

    (async () => {
      setIsGalleryLoading(true);
      const gG = await getGroupsGallery(filters);
      if (gG) setGalleryGroupsItems(gG);
      setIsGalleryLoading(false);
    })();
  }, [searchParams.get("tags"), searchParams.get("sort")]);

  useEffect(() => {
    (async () => {
      const c = await getCategories();
      setTags(c);
    })();
  }, []);

  return (
    <section id="groups-gallery-view">
      <Dots className="dots" />
      <main className="content f-column y-start">
        <div className="header-content f-column y-start x-start">
          <motion.header
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Grupy zdjęć
          </motion.header>

          <motion.p
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.7,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Grupy zdjęć wykonanych podczas remontów u naszych klientów.
          </motion.p>
        </div>

        <GalleryFilters tags={tags} activeTags={activeTags} />

        <GroupGalleryWrapper
          isLoading={isGalleryLoading}
          groupGallery={galleryGroupsItems}
        />
      </main>
    </section>
  );
};

export default GroupsGallery;
