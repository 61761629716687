import React, { FC } from "react";
import { NAV_LINKS } from "../../config";
import { CONTACT_INFO, LANDING_OFFER_ITEMS, OFERTEO_LINK } from "../../data";

const Footer: FC = () => {
  return (
    <footer>
      <div className="logo-column f-column y-start x-start">
        <a href="/" className="logo">
          <span className="c-primary">Rem</span>
          <span>Art</span>
        </a>

        <div className="copyright-info f-column y-start x-start">
          <div className="copyright f-column">
            <span>Copyright © 2023 - REMART</span>
            <span>Wszelkie prawa zastrzeżone.</span>
          </div>

          <span>
            Zobacz nas na{" "}
            <a href={OFERTEO_LINK} className="c-primary" target="_blank">
              oferteo.pl
            </a>
          </span>
        </div>
      </div>

      <div className="links-column-wrapper">
        <div className="links-column f-column y-start x-start">
          <span className="links-column-header">Menu</span>

          <div className="footer-links f-column y-start x-start">
            {NAV_LINKS?.map((link) => (
              <a href={link.link} className="footer-link" key={link.id}>
                {link.name}
              </a>
            ))}
          </div>
        </div>

        <div className="links-column f-column y-start x-start">
          <span className="links-column-header">Oferta</span>

          <div className="footer-links f-column y-start x-start">
            {LANDING_OFFER_ITEMS?.map((offer) => (
              <a href="/oferta/" className="footer-link" key={offer.id}>
                {offer.title}
              </a>
            ))}
          </div>
        </div>

        <div className="links-column f-column y-start x-start">
          <span className="links-column-header">Kontakt</span>

          <div className="footer-links f-column y-start x-start">
            <a href={`tel:${CONTACT_INFO.phone}`} className="footer-link">
              {CONTACT_INFO.phone}
            </a>
            <a href={`mailto:${CONTACT_INFO.email}`} className="footer-link">
              {CONTACT_INFO.email}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
