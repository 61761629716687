import { useLocation, Outlet, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Cookie from "js-cookie";
import { AUTH_COOKIE } from "../../config";

const AuthGateway = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const token = Cookie.get(AUTH_COOKIE);

  return auth?.id ||
    (token && auth?.accessToken && token === auth?.accessToken) ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/panel/login" state={{ from: location }} replace />
  );
};

export default AuthGateway;
