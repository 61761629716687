import React, { FC, useEffect, useState } from "react";
import Hamburger from "../../components/Hamburger/Hamburger";
import {
  AnimatePresence,
  motion,
  useCycle,
  useScroll,
  Variants,
} from "framer-motion";
import { NAV_LINKS } from "../../config";
import { CONTACT_INFO } from "../../data";
import Logo from "../../components/Logo/Logo";

interface NavigationProps {
  invert?: boolean;
}

const Navigation: FC<NavigationProps> = ({ invert = false }) => {
  const { scrollY } = useScroll();

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [navbarState, setNavbarState] = useState<number>(0);
  const [sidebar, toggleSidebar] = useCycle(false, true);

  const updateNav = (): void => {
    if (scrollY.get() < scrollY?.getPrevious() && scrollY.get() > 10) {
      setNavbarState(-1);
    } else if (
      scrollY?.get() > 50 &&
      scrollY.get() < window.innerHeight &&
      scrollY?.get() > scrollY?.getPrevious()
    ) {
      setNavbarState(1);
    } else if (
      scrollY.get() > window.innerHeight &&
      scrollY?.get() > scrollY?.getPrevious()
    ) {
      setNavbarState(2);
    } else {
      setNavbarState(0);
    }
  };

  useEffect(() => {
    updateNav();

    return scrollY.onChange(() => updateNav());
  }, []);

  const SidebarVariants: Variants = {
    hidden: {
      transform: "translateX(100%)",
      transition: {
        duration: 0.2,
      },
    },
    shown: {
      transform: "translateX(0)",
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const MenuImageVariants: Variants = {
    hidden: {
      transform: "translateX(-100%) scale(.6)",
      opacity: 0,
      filter: "blur(20px)",
      transition: {
        transform: {
          duration: 0,
          delay: 0.3,
        },
        opacity: {
          duration: 0.3,
        },
      },
    },
    shown: {
      transform: "translateX(0) scale(1)",
      scale: 1,
      opacity: 1,
      filter: "blur(0px)",
      transition: {
        filter: {
          duration: 1,
          delay: 0.3,
        },
        transform: {
          duration: 0,
        },
      },
    },
  };

  useEffect(() => {
    toggleSidebar(isMenuOpened ? 1 : 0);
  }, [isMenuOpened]);

  return (
    <nav
      id="navigation"
      role="navigation"
      data-state={navbarState}
      data-invert={invert}
    >
      <div className="nav-bar flex y-center x-between">
        <a href={`tel:${CONTACT_INFO.phone}`} className="phone-number">
          {CONTACT_INFO.phone}
        </a>

        <Logo />

        <Hamburger isOpened={isMenuOpened} setIsOpened={setIsMenuOpened} />
      </div>

      <AnimatePresence>
        {sidebar && (
          <>
            <motion.div
              className="side-menu-image"
              variants={MenuImageVariants}
              initial="hidden"
              animate={sidebar ? "shown" : "hidden"}
              exit="hidden"
            ></motion.div>
            <motion.aside
              className="sidebar f-column y-start x-start"
              variants={SidebarVariants}
              initial="hidden"
              animate={sidebar ? "shown" : "hidden"}
              exit="hidden"
            >
              <div className="logo">
                <span>RemArt</span>
              </div>

              <ul className="nav-links f-column">
                {NAV_LINKS.map((link, i) => (
                  <a href={link.link} key={link.id}>
                    <li data-delay={{ "--i": i * 0.1 }}>{link.name}</li>
                  </a>
                ))}
              </ul>

              <a
                href={`tel:${CONTACT_INFO.phone}`}
                className="menu-phone-number"
              >
                {CONTACT_INFO.phone}
              </a>
            </motion.aside>
          </>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navigation;
