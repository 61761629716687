import React, { FC } from "react";
import Loader from "../Loader/Loader";

const Offline: FC = () => {
  return (
    <div className="offline-container flex y-center x-center">
      <div className="content flex f-column y-center x-center">
        <header>Nie masz połączenia z internetem</header>
        <p>Wykryto problem z twoim łączem internetowym</p>

        <Loader />
      </div>
    </div>
  );
};

export default Offline;
