import React, { FC, useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import {
  motion,
  useScroll,
  useTransform,
  useInView,
  Variants,
} from "framer-motion";
import {
  CustomEase1,
  FadeLeftVariants,
  FadeUpVariants,
} from "../../config/variants";
import Header from "../../components/Header/Header";
import NumberCounter from "../../components/NumberCounter/NumberCounter";
import CarouselCover from "../../assets/webp/carousel-cover.webp";
import LandingBackground from "../../assets/webp/landing-bg.webp";
import CarouselImage from "../../assets/webp/carousel-image.webp";
import { useSwipeable } from "react-swipeable";
import { LANDING_OFFER_ITEMS, OFERTEO_LINK, REVIEWS } from "../../data";
import LandingOfferItem from "../../components/LandingOfferItem/LandingOfferItem";
import LandingOfferImage from "../../components/LandingOfferImage/LandingOfferImage";
import { ReactComponent as Dots } from "../../assets/svg/dots.svg";
import ReviewItem from "../../components/ReviewItem/ReviewItem";
import OferteoBadge from "../../assets/webp/oferteo-badge-1000x1000.webp";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";

const Landing: FC = () => {
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const reviewsSectionRef = useRef<HTMLDivElement>(null);
  const carouselImageWrapperRef = useRef<HTMLDivElement>(null);
  const workCarouselRef = useRef<HTMLDivElement>(null);

  const isReviewsSectionInView = useInView(reviewsSectionRef, {
    once: false,
  });

  const [carouselWidth, setCarouselWidth] = useState<number>(0);
  const [carouselPos, setCarouselPos] = useState<number>(0);

  const [activeOfferItemId, setActiveOfferId] = useState<number | null>(null);

  const workImages = [CarouselCover, LandingBackground, CarouselImage];

  const carouselHandlers = useSwipeable({
    preventScrollOnSwipe: true,
    trackMouse: true,
    onSwiped: (eventData) => {
      switch (eventData.dir) {
        case "Left":
          swipeLeft();
          break;
        case "Right":
          swipeRight();
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setCarouselWidth(
      workCarouselRef.current!.scrollWidth -
        workCarouselRef.current!.offsetWidth
    );
  }, [carouselWidth]);

  useEffect(() => {
    setCarouselWidth(
      workCarouselRef.current!.scrollWidth -
        workCarouselRef.current!.offsetWidth
    );
  }, []);

  const isAboutSectionInView = useInView(aboutSectionRef, {
    once: true,
    margin: "-120px",
  });

  const { scrollY: heroSectionScrollY } = useScroll({
    target: aboutSectionRef,
    offset: ["end end", "end start"],
  });
  const heroButtonY = useTransform(
    heroSectionScrollY,
    [0, 800],
    ["0%", "-400%"]
  );
  const heroParagraphY = useTransform(
    heroSectionScrollY,
    [0, 450],
    ["0%", "-500%"]
  );
  const heroHeaderY = useTransform(
    heroSectionScrollY,
    [0, 500],
    ["0%", "-100%"]
  );

  const OfferImageWrapperVariants: Variants = {
    initial: {
      opacity: 0,
      transition: {
        ease: CustomEase1,
        duration: 0.5,
        opacity: {
          duration: 0.5,
        },
      },
    },
    fadeIn: {
      opacity: 1,
      transition: {
        ease: CustomEase1,
        duration: 0.5,
        opacity: {
          duration: 0.5,
        },
      },
    },
  };

  const reviewsDuration = 120;
  const ReviewRowVariants1: Variants = {
    animate: {
      x: ["-100%", "0%"],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: reviewsDuration,
          ease: "linear",
        },
      },
    },
  };

  const ReviewRowVariants2: Variants = {
    animate: {
      x: ["0%", "-100%"],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: reviewsDuration,
          ease: "linear",
        },
      },
    },
  };

  const swipeLeft = () => {
    if (carouselPos < workImages.length - 1) setCarouselPos(carouselPos + 1);
  };

  const swipeRight = () => {
    if (carouselPos > 0) setCarouselPos(carouselPos - 1);
  };

  const getCarouselItemPos = (): string => {
    const itemWidth: number =
      document.querySelector(".work-carousel .item")?.clientWidth || 350;

    if (!workCarouselRef.current?.offsetWidth) {
      return `0px`;
    }

    if (carouselPos === 0) {
      return `${(workCarouselRef.current!.offsetWidth - itemWidth) / 2}px`;
    }

    return `${
      (workCarouselRef.current!.offsetWidth - itemWidth) / 2 -
      (carouselPos * itemWidth + 4 * 16) -
      (carouselPos - 1) * 48
    }px`;
  };

  return (
    <>
      <section id="landing">
        <div className="section-background">
          <div className="bg-image"></div>
          <div className="bg-gradient"></div>
        </div>

        <main className="content f-column y-center x-center">
          <div className="text-content f-column y-center x-center">
            <motion.header
              style={{ y: heroHeaderY }}
              initial="initial"
              animate="fadeUp"
              variants={FadeUpVariants}
              transition={{
                delay: 0.3,
                duration: 0.8,
                ease: CustomEase1,
                opacity: { duration: 1.5 },
              }}
            >
              Profesjonalne wykończenie wnętrz - od małych poprawek po
              kompleksowe remonty.
            </motion.header>
            <motion.p
              style={{ y: heroParagraphY }}
              initial="initial"
              animate="fadeUp"
              variants={FadeUpVariants}
              transition={{
                delay: 0.7,
                duration: 0.8,
                ease: CustomEase1,
                opacity: { duration: 1.5 },
              }}
            >
              Odśwież swoje wnętrza z naszą pomocą - remonty łazienek i nie
              tylko.
            </motion.p>
          </div>
          <motion.div
            style={{ y: heroButtonY }}
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 1.5,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            <Button
              text="Poznaj nas"
              action="link"
              path="#o-nas"
              color="white"
            />
          </motion.div>
        </main>
      </section>

      <section ref={aboutSectionRef} id="o-nas">
        <main className="content f-column y-start x-start">
          <Header title="O nas" subtitle="Parę słów o firmie REMART" />

          <div className="content-wrapper f-column y-start x-start">
            <div className="company-info f-column">
              <motion.p
                initial="initial"
                whileInView="fadeUp"
                viewport={{ once: true }}
                variants={FadeUpVariants}
                transition={{
                  duration: 0.8,
                  ease: CustomEase1,
                  opacity: { duration: 1.5 },
                  delay: 0.7,
                }}
              >
                Profesjonalna firma wykończeniowa z ponad 20 letnim
                doświadczeniem i mnóstwem zadowolonych klientów. Działalność
                prowadzimy na terenie woj. świętokrzyskiego, oscylując głównie w
                okolicach miast tj. Kielce, Chmielnik i Busko-Zdrój.
              </motion.p>

              <motion.p
                initial="initial"
                whileInView="fadeUp"
                viewport={{ once: true }}
                variants={FadeUpVariants}
                transition={{
                  delay: 1.1,
                  duration: 0.8,
                  ease: CustomEase1,
                  opacity: { duration: 1.5 },
                }}
              >
                Wykonujemy m.in. kompleksowe wykończenia, remonty łazienek wraz
                z białym montażem, przeróbki hydrauliki oraz elektryki dla
                klientów prywatnych jak i dla tych komercyjnych.
              </motion.p>

              <motion.div
                initial="initial"
                whileInView="fadeUp"
                viewport={{ once: true }}
                variants={FadeUpVariants}
                transition={{
                  delay: 1.5,
                  duration: 0.8,
                  ease: CustomEase1,
                  opacity: { duration: 1.5 },
                }}
              >
                <Button text="Skontaktuj się" action="link" path="/kontakt" />
              </motion.div>
            </div>

            <div className="stats-counters flex y-center">
              <div className="stats-box f-column y-start x-start">
                <div className="flex y-center">
                  <NumberCounter
                    value={30}
                    isInView={isAboutSectionInView}
                    delay={0}
                  />
                  <span className="number-counter">+</span>
                </div>
                <span className="stats-title">Zadowolonych klientów</span>
              </div>

              <div className="stats-box f-column y-start x-start">
                <NumberCounter
                  value={20}
                  isInView={isAboutSectionInView}
                  delay={0}
                />
                <span className="stats-title">Lat doświadczenia w branży</span>
              </div>
            </div>
          </div>
        </main>
      </section>

      <section id="our-work" ref={carouselImageWrapperRef}>
        <main className="content f-column y-center x-center">
          <div className="fade-wrapper" data-fade={carouselPos > 0}>
            <Header
              title="Realizacje"
              subtitle="Kilka ujęć z ostatnich prac wykończeniowych, które wykonaliśmy."
              align="center"
            />
          </div>

          <motion.div
            {...carouselHandlers}
            className="work-carousel"
            ref={workCarouselRef}
            variants={FadeLeftVariants}
            initial={{
              opacity: 0,
              x: 50,
              y: (window.innerWidth || 0) >= 992 ? "-50%" : "initial",
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: (window.innerWidth || 0) >= 992 ? "-50%" : "initial",
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
              delay: 0.7,
            }}
          >
            <motion.div
              className="inner-work-carousel flex y-center"
              initial={{ x: 0 }}
              animate={{
                x: getCarouselItemPos(),
              }}
              transition={{
                type: "spring",
                ease: CustomEase1,
                duration: 0.2,
                stiffness: 260,
                damping: 20,
              }}
            >
              {workImages?.map((image, i) => (
                <motion.div
                  key={i}
                  className="item"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  data-bg-pos={
                    i === carouselPos
                      ? "center"
                      : i === carouselPos - 1
                      ? "left"
                      : i === carouselPos + 1
                      ? "right"
                      : "center"
                  }
                ></motion.div>
              ))}
            </motion.div>

            <div className="carousel-nav">
              {carouselPos > 0 && (
                <div className="carousel-nav-left" onClick={swipeRight}>
                  <Arrow className="arrow" width={32} height={32} />
                </div>
              )}
              {carouselPos < workImages.length - 1 && (
                <div className="carousel-nav-right" onClick={swipeLeft}>
                  <Arrow className="arrow" width={32} height={32} />
                </div>
              )}
            </div>
          </motion.div>

          <motion.div
            variants={FadeUpVariants}
            initial="initial"
            whileInView="fadeUp"
            viewport={{ once: true }}
            transition={{
              ease: CustomEase1,
              delay: 1,
              duration: 0.8,
              opacity: { duration: 1 },
            }}
          >
            <Button text="Zobacz więcej" action="link" path="/galeria" />
          </motion.div>
        </main>
      </section>

      <section id="offer">
        <Dots className="dots-illustration" />
        <main className="content f-column y-center x-center">
          <Header
            title="Oferta"
            subtitle="Oferujemy wiele profesjonalnych i najlepszych na rynku usług remontowych."
            align="center"
          />

          <div className="offer-items-columns flex">
            <div className="offer-items-column">
              <ul>
                {LANDING_OFFER_ITEMS?.map((item) => (
                  <li key={item.id}>
                    <LandingOfferItem
                      text={item.title}
                      description={item.description}
                      setActiveId={setActiveOfferId}
                      id={item.id}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="offer-image-column">
              <motion.div
                className="offer-image-wrapper"
                variants={OfferImageWrapperVariants}
                initial="initial"
                whileInView="fadeIn"
                viewport={{ margin: "-50% 0px -20% 0px" }}
              >
                {LANDING_OFFER_ITEMS?.map((item) => (
                  <LandingOfferImage
                    image={item.image}
                    isActive={activeOfferItemId === item.id}
                    key={item.id}
                  />
                ))}
              </motion.div>
            </div>
          </div>
        </main>
      </section>

      <section id="reviews" ref={reviewsSectionRef}>
        <Dots className="dots-illustration" />
        <main className="content f-column y-center x-start">
          <Header
            title="Opinie"
            subtitle="Co mówią o nas nasi klienci"
            align="center"
          />

          <div className="reviews f-column y-center x-center">
            <div className="reviews-row flex y-center">
              <motion.div
                className="row"
                variants={ReviewRowVariants1}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
              <motion.div
                className="row"
                variants={ReviewRowVariants1}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
            </div>

            <div className="reviews-row flex y-center">
              <motion.div
                className="row"
                variants={ReviewRowVariants2}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
              <motion.div
                className="row"
                variants={ReviewRowVariants2}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
            </div>

            <div className="reviews-row flex y-center">
              <motion.div
                className="row"
                variants={ReviewRowVariants1}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.reverse()?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
              <motion.div
                className="row"
                variants={ReviewRowVariants1}
                animate={isReviewsSectionInView ? "animate" : "initial"}
              >
                {REVIEWS?.reverse()?.map((review) => (
                  <ReviewItem review={review} key={review.id} />
                ))}
              </motion.div>
            </div>
          </div>
        </main>
      </section>

      <section id="oferteo">
        <main className="content f-column y-center x-center">
          <div className="oferteo-box f-column y-center x-center">
            <img src={OferteoBadge} alt="oferteo" loading="lazy" />
            <p>
              W 2022 roku otrzymaliśmy nagrodę Najlepsi 2022 za znakomite opinie
              naszych klientów. Przeczytaj więcej opinii o naszych usługach na
              platformie Oferteo.pl
            </p>
            <Button text="Zobacz opinie" action="link" path={OFERTEO_LINK} />
          </div>
        </main>
      </section>
      <section style={{ minHeight: "50vh" }}>
        <ContactUsBlock />
      </section>
    </>
  );
};

export default Landing;
