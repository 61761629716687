import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as MarkerIcon } from "../../../assets/svg/marker.svg";
import { getGroup } from "../../../firebase/api/gallery/getGroup";
import GalleryGroup from "../../../interfaces/GalleryGroup";
import GalleryItem from "../../../interfaces/GalleryItem";
import { getGallery } from "../../../firebase/api/gallery/getGallery";
import { motion } from "framer-motion";
import GalleryItemTile, {
  ImageVariants,
  ThumbnailVariants,
} from "../../../components/GalleryItemTile/GalleryItemTile";
import Skeleton from "react-loading-skeleton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import PanelButton from "../../../components/PanelButton/PanelButton";
import SkeletonGalleryItemTile from "../../../components/skeleton/SkeletonGalleryItemTile/SkeletonGalleryItemTile";
import FullscreenImageView from "../../../components/FullscreenImageView/FullscreenImageView";
import ComebackButton from "../../../components/ComebackButton/ComebackButton";

const PanelGalleryGroup: FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [group, setGroup] = useState<GalleryGroup | null>(null);
  const [images, setImages] = useState<GalleryItem[] | null>(null);
  const [isFullscreenViewVisible, setIsFullscreenViewVisible] =
    useState<boolean>(false);
  const [clickedGalleryItem, setClickedGalleryItem] =
    useState<GalleryItem | null>(null);

  useEffect(() => {
    if (!groupId) return;

    (async () => {
      setIsLoading(true);

      Promise.all([
        await getGroup(groupId),
        await getGallery({ groupId }),
      ]).then((result) => {
        const [g, i] = result;
        if (g && i) {
          setGroup(g);
          setImages(i);
        }
      });

      setIsLoading(false);
    })();
  }, []);

  const onImageLoad = (): void => {
    setImageLoaded(true);
  };

  const onSelectItemsChange = (status: boolean): void => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      select: "" + status,
    }));
  };

  const showFullscreenView = (item: GalleryItem | undefined): void => {
    if (!item) return;

    setIsFullscreenViewVisible(true);
    setClickedGalleryItem(item);
  };

  const onFullscreenViewHide = (): void => {
    setIsFullscreenViewVisible(false);
    setClickedGalleryItem(null);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "grid",
      imageId: "",
    }));
  };

  const onGalleryItemClick = (item: GalleryItem): void => {
    showFullscreenView(item);
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      view: "fullscreen",
      imageId: item.id,
    }));
  };

  return (
    <section id="panel-gallery-group-view" className="panel-view">
      <main className="content f-column y-start x-start">
        <div className="actions-bar flex y-center x-between">
          <ComebackButton
            path="/admin/panel/galeria?mode=groups"
            label="Lista grup"
          />

          <div className="actions-wrapper flex y-center x-end">
            <Checkbox
              id="select-checkbox"
              label="Zaznacz"
              onChange={onSelectItemsChange}
            />

            <PanelButton
              text="Dodaj"
              action="link"
              path={`/admin/panel/galeria/dodaj-zdjecie?groupId=${groupId}`}
            />
          </div>
        </div>

        <div className="group-info flex y-start x-start">
          {!isLoading ? (
            <motion.div
              className="thumbnail"
              style={{ backgroundImage: `url(${group?.coverImageThumbnail})` }}
              variants={ThumbnailVariants}
              initial="blur"
              animate={imageLoaded ? "noBlur" : "blur"}
            >
              <motion.img
                src={group?.coverImageUrl || undefined}
                alt={group?.coverImageUrl || undefined}
                variants={ImageVariants}
                initial="hidden"
                animate={imageLoaded ? "shown" : "hidden"}
                loading="lazy"
                onLoad={onImageLoad}
              />
            </motion.div>
          ) : (
            <Skeleton
              height="100%"
              width="100%"
              containerClassName="flex-1 skeleton-image"
            />
          )}

          <div className="text-content f-column y-start x-start">
            {!isLoading ? (
              <>
                <header>{group?.groupName}</header>
                <p>{group?.groupDescription}</p>
                <span>
                  <MarkerIcon width={20} height={20} /> {group?.localization}
                </span>
              </>
            ) : (
              <>
                <Skeleton width={120} height={40} />
                <Skeleton
                  inline
                  count={11}
                  height={9}
                  width={420}
                  containerClassName="flex-1 f-column skeleton-p"
                />
                <span>
                  <MarkerIcon width={20} height={20} />
                  <Skeleton width={100} height={10} />
                </span>
              </>
            )}
          </div>
        </div>

        <div className="panel-gallery-images-wrapper f-column">
          <div className="gallery-content-bar">
            {!isLoading ? (
              <span>Wyniki ({images?.length || 0})</span>
            ) : (
              <Skeleton width={100} />
            )}
          </div>
          <div className="group-images">
            {!isLoading
              ? images &&
                images?.map((image) => (
                  <GalleryItemTile
                    data={image}
                    onClick={onGalleryItemClick}
                    key={image.id}
                  />
                ))
              : Array(8)
                  .fill(2)
                  .map(Math.pow)
                  .map((s) => <SkeletonGalleryItemTile key={s} />)}

            <FullscreenImageView
              current={clickedGalleryItem}
              isVisible={isFullscreenViewVisible}
              onHide={onFullscreenViewHide}
              gallery={images}
            />
          </div>
        </div>
      </main>
    </section>
  );
};

export default PanelGalleryGroup;
