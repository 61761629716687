import { FC } from "react";
import { useScript } from "usehooks-ts";

const HotjarLoad: FC = () => {
  useScript("https://static.hotjar.com/c/hotjar-3788461.js?sv=6", {
    removeOnUnmount: false,
  });

  return <></>;
};

export default HotjarLoad;
