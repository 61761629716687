import React, { FC } from "react";

const SmallLoader: FC = () => {
  return (
    <div className="small-loader">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SmallLoader;
