import React, { FC } from "react";
import { Path, RegisterOptions, UseFormRegister } from "react-hook-form";

export interface InputProps {
  id: string;
  label: Path<any>;
  register: UseFormRegister<any>;
  example?: string;
  required?: boolean;
  error?: string;
  options?: RegisterOptions;
}

const Input: FC<InputProps> = ({
  id,
  label,
  example,
  register,
  required = false,
  error,
  options,
}) => {
  return (
    <div className="input-wrapper f-column y-start x-start">
      <label htmlFor={id}>{label}</label>
      <input
        type="text"
        id={id}
        placeholder={example}
        {...register(label, { required, ...options })}
      />
      <small className="error">{error}</small>
    </div>
  );
};

export default Input;
