import React, { FC, useEffect, useState } from "react";
import GalleryItem from "../../interfaces/GalleryItem";
import { AnimatePresence, motion, Variants, wrap } from "framer-motion";
import {
  GALLERY_ITEM_LOCALE_DATE_OPTIONS,
  ImageVariants,
  ThumbnailVariants,
} from "../GalleryItemTile/GalleryItemTile";
import GalleryTag from "../GalleryTag/GalleryTag";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";

interface FullscreenImageViewProps {
  current: GalleryItem | null;
  isVisible: boolean;
  onHide: () => void;
  gallery: GalleryItem[] | null;
}

const FullscreenImageView: FC<FullscreenImageViewProps> = ({
  current,
  isVisible,
  onHide,
  gallery,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    if (!gallery) return;

    const navEl = document.getElementById("navigation");
    const scrollToTopEl = document.getElementById("scroll-to-top");

    if (isVisible) {
      setPage([
        gallery.findIndex((item) => item.id === (current?.id || "")),
        0,
      ]);

      if (navEl && scrollToTopEl) {
        navEl!.hidden = true;
        scrollToTopEl!.setAttribute("hidden", "true");
      }
    } else {
      if (navEl && scrollToTopEl) {
        navEl!.hidden = false;
        scrollToTopEl!.setAttribute("hidden", "false");
      }
    }
  }, [isVisible]);

  const paginate = (newDirection: number) => {
    setLoaded(false);
    setPage([page + newDirection, newDirection]);
  };

  const SWIPE_CONFIDENCE_THRESHOLD = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const onImageLoad = (): void => {
    setLoaded(true);
  };

  const onExit = (): void => {
    setLoaded(false);
    onHide();
  };

  const onPaginatorClick = (
    e: React.MouseEvent<HTMLDivElement>,
    direction: number
  ) => {
    e.stopPropagation();
    paginate(direction);
  };

  const FsViewVariants: Variants = {
    hidden: {
      zIndex: "var(--modal-z)",
      opacity: 0,
    },

    visible: {
      opacity: 1,
      zIndex: "var(--modal-z)",
      transition: {
        duration: 0.3,
        type: "ease",
      },
    },
  };

  const ContentVariants: Variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const imageIndex = wrap(0, gallery?.length || 0, page);
  const currentImage: GalleryItem | null = gallery ? gallery[imageIndex] : null;

  return (
    <AnimatePresence>
      {currentImage && isVisible && (
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className="fullscreen-image-view flex y-center x-center"
            variants={FsViewVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={onExit}
          >
            <motion.div
              className="content f-column y-center x-center"
              key={page}
              custom={direction}
              variants={ContentVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                e.stopPropagation();
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) {
                  paginate(1);
                } else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) {
                  paginate(-1);
                }
              }}
            >
              <motion.div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${currentImage?.thumbnail_url})`,
                }}
                variants={ThumbnailVariants}
                initial="blur"
                animate={loaded ? "noBlur" : "blur"}
              >
                <motion.img
                  className="image"
                  src={currentImage?.url}
                  alt={currentImage?.id}
                  variants={ImageVariants}
                  initial="hidden"
                  animate={loaded ? "shown" : "hidden"}
                  onLoad={onImageLoad}
                  loading="lazy"
                />
              </motion.div>
              <div className="image-info flex y-center x-between">
                <span className="date">
                  {currentImage.createdAt
                    ?.toDate()
                    .toLocaleDateString(
                      "pl-PL",
                      GALLERY_ITEM_LOCALE_DATE_OPTIONS
                    )}
                </span>

                <div className="tags flex y-center x-center">
                  {currentImage?.tags?.map((tag) => (
                    <GalleryTag
                      text={tag}
                      isActive={true}
                      key={tag}
                      size="small"
                    />
                  ))}
                </div>
              </div>
            </motion.div>

            <div className="paginator" onClick={(e) => onPaginatorClick(e, -1)}>
              <ArrowIcon width={64} height={64} />
            </div>
            <div
              className="paginator right"
              onClick={(e) => onPaginatorClick(e, 1)}
            >
              <ArrowIcon width={64} height={64} />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </AnimatePresence>
  );
};

export default FullscreenImageView;
