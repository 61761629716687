import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import PanelSidebar from "./PanelSidebar";
import PanelTopbar from "./PanelTopbar";

const PanelLayout: FC = () => {
  return (
    <div className="panel-layout flex">
      <PanelSidebar />

      <div className="panel-content f-column y-start x-center">
        <PanelTopbar />

        <div className="panel-content-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PanelLayout;
