import React, { FC } from "react";
import { motion } from "framer-motion";
import { CustomEase1, FadeUpVariants } from "../../config/variants";
import { ReactComponent as Dots } from "../../assets/svg/dots.svg";
import { OFFER_TILES_DATA } from "../../data";
import OfferTileItem from "../../components/OfferTileItem/OfferTileItem";
import ContactUsBlock from "../../components/ContactUsBlock/ContactUsBlock";

const Offer: FC = () => {
  return (
    <section id="offer">
      <Dots className="dots" />
      <main className="content f-column y-start">
        <div className="header-content f-column y-start x-start">
          <motion.header
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Poznaj naszą ofertę
          </motion.header>

          <motion.p
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.7,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Główne usługi wykonywane przez naszą firmę.
          </motion.p>
        </div>

        <div className="offer-content f-column y-start x-start">
          <motion.header
            variants={FadeUpVariants}
            initial="initial"
            animate="fadeUp"
            transition={{
              delay: 1,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Co możemy dla <span>ciebie</span> zrobić?
          </motion.header>

          <div className="offer-tiles f-column y-start x-center">
            {OFFER_TILES_DATA?.map((tile) => (
              <OfferTileItem data={tile} key={tile.id} />
            ))}
          </div>
        </div>

        <ContactUsBlock />
      </main>
    </section>
  );
};

export default Offer;
