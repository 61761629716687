import React, { FC, useState } from "react";
import Input from "../../../components/Input/Input";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export interface LoginFormValues {
  "Adres e-mail": string;
  Hasło: string;
}

interface LoginFormProps {
  onFormSubmit: (values: LoginFormValues) => Promise<void>;
}

const LoginForm: FC<LoginFormProps> = ({ onFormSubmit }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const requiredMessage: string = "To pole nie może być puste";
  const validationSchema = yup.object({
    "Adres e-mail": yup
      .string()
      .required(requiredMessage)
      .email("Fraza nie jest adresem e-mail."),
    Hasło: yup.string().required(requiredMessage),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({ resolver: yupResolver(validationSchema) });

  const onSubmit: SubmitHandler<LoginFormValues> = async (
    data: LoginFormValues,
    event
  ): Promise<void> => {
    event?.preventDefault();

    setIsLoading(true);
    await onFormSubmit(data);
    setIsLoading(false);
  };

  return (
    <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="email-input"
        label="Adres e-mail"
        example="jan@poczta.pl"
        register={register}
        required
        error={errors["Adres e-mail"]?.message}
      />

      <PasswordInput
        id="passwd-input"
        label="Hasło"
        register={register}
        error={errors["Hasło"]?.message}
        required
      />

      <SubmitButton text="Zaloguj" isLoading={isLoading} />
    </form>
  );
};

export default LoginForm;
