import React, { FC } from "react";
import Logo from "../../components/Logo/Logo";
import PanelUser from "../../components/PanelUser/PanelUser";

const PanelTopbar: FC = () => {
  return (
    <div className="panel-topbar flex y-center x-between">
      <Logo />
      <PanelUser />
    </div>
  );
};

export default PanelTopbar;
