import React, { FC } from "react";
import Review from "../../interfaces/Review";
import { ReactComponent as Star } from "../../assets/svg/star.svg";

interface ReviewItemProps {
  review: Review;
}

const ReviewItem: FC<ReviewItemProps> = ({ review }) => {
  return (
    <div className="review-item f-column y-start x-start">
      <div className="review-stars">
        {Array(review.stars)
          .fill(0)
          .map((v) => v + 1)
          .map((v, i) => (
            <Star
              fill="var(--primary-color)"
              width={16}
              height={16}
              key={"star-" + i}
            />
          ))}
      </div>
      <div className="review-header">
        <div className="author">{review.author}</div>
      </div>

      <p className="review-content">{review.content}</p>
    </div>
  );
};

export default ReviewItem;
