import React, { FC, FunctionComponent, SVGProps } from "react";
import { AnimatePresence } from "framer-motion";

export type PanelActionBarAction = {
  id: string;
  name: string;
  callback: () => void;
  Icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
};

interface PanelActionsBarProps {
  actions: PanelActionBarAction[];
  isVisible: boolean;
}

const PanelActionsBar: FC<PanelActionsBarProps> = ({ isVisible, actions }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <div className="panel-actions-bar flex y-center x-center">
          {actions.map((action) => (
            <div
              className="action flex y-center"
              key={action.id}
              data-id={action.id}
              onClick={action.callback}
            >
              {action.Icon && <action.Icon />}
              <span>{action.name}</span>
            </div>
          ))}
        </div>
      )}
    </AnimatePresence>
  );
};

export default PanelActionsBar;
