import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { AnimatePresence } from "framer-motion";
import GalleryGroupItemTile from "../GalleryGroupItemTile/GalleryGroupItemTile";
import { GroupsGalleryData } from "../../firebase/api/gallery/getGroupsGallery";
import SkeletonGalleryGroupItemTile from "../skeleton/SkeletonGalleryGroupItemTile/SkeletonGalleryGroupItemTile";

interface GroupGalleryWrapperProps {
  isLoading: boolean;
  groupGallery: GroupsGalleryData | null;
}

const GroupGalleryWrapper: FC<GroupGalleryWrapperProps> = ({
  isLoading,
  groupGallery,
}) => {
  return (
    <div className="gallery-content-wrapper f-column y-start x-start">
      <div className="gallery-content-bar">
        {!isLoading && groupGallery ? (
          <span>Wyniki ({Object.keys(groupGallery)?.length || 0})</span>
        ) : (
          <Skeleton width={100} />
        )}
      </div>

      <AnimatePresence mode="popLayout">
        <div className="group-gallery-wrapper">
          {!isLoading && groupGallery
            ? Object.keys(groupGallery)?.map(
                (groupId: keyof GroupsGalleryData) => {
                  return (
                    <GalleryGroupItemTile
                      data={groupGallery[groupId]}
                      key={groupId}
                    />
                  );
                }
              )
            : Array(8)
                .fill(2)
                .map(Math.pow)
                .map((s) => <SkeletonGalleryGroupItemTile key={s} />)}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default GroupGalleryWrapper;
