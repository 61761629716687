import { FC, useEffect, useRef } from "react";
import { useMotionValue, useSpring } from "framer-motion";

interface NumberCounterProps {
  value: number;
  isInView: boolean;
  direction?: "up" | "down";
  delay?: number;
}

const NumberCounter: FC<NumberCounterProps> = ({
  value,
  isInView,
  direction = "up",
  delay = 0,
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 70,
    stiffness: 100,
    duration: 1.5,
  });

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        motionValue.set(direction === "down" ? 0 : value);
      }, delay);
    }
  }, [motionValue, isInView]);

  useEffect(
    () =>
      springValue.on("change", (latest) => {
        if (ref.current) {
          ref.current.textContent = Intl.NumberFormat("pl-PL").format(
            latest.toFixed(0)
          );
        }
      }),
    [springValue]
  );

  return <span className="number-counter" ref={ref} />;
};

export default NumberCounter;
