import LandingOfferItem from "../interfaces/LandingOfferItem";

import Plytki from "../assets/webp/offer-items/glazura-i-terakota.webp";
import Lazienki from "../assets/webp/offer-items/lazienki.webp";
import Malowanie from "../assets/webp/offer-items/malowanie-i-tynkowanie.webp";
import Panele from "../assets/webp/offer-items/panele-podlogowe.webp";
import Hydraulika from "../assets/webp/offer-items/hydraulika-i-elektryka.webp";
import Review from "../interfaces/Review";
import OfferTile from "../interfaces/OfferTile";
import BathroomImage from "../assets/webp/menu-bg.webp";
import PaintingImage from "../assets/webp/offer-items/malowanie-i-tynkowanie.webp";

export const CONTACT_INFO = {
  phone: "+48 697 658 482",
  email: "artekiskra@o2.pl",
};

export const LANDING_OFFER_ITEMS: LandingOfferItem[] = [
  {
    id: 0,
    title: "Łazienki",
    description:
      "Tworzymy wymarzone łazienki, które służą latami. Podążamy za obecnymi trendami oraz rozwiązaniami dostępnymi na rynku.",
    image: Lazienki,
  },
  {
    id: 1,
    title: "Malowanie i tynkowanie",
    description: "Tynkujemy ściany i je malujemy.",
    image: Malowanie,
  },
  {
    id: 2,
    title: "Glazura i terakota",
    description:
      "Układamy płytki standardowych rozmiarów jak i te wielo-formatowe.",
    image: Plytki,
  },
  {
    id: 3,
    title: "Panele podłogowe, deska barlinecka",
    description: "Kładziemy panele podłogowe i deskę barlinecką.",
    image: Panele,
  },
  {
    id: 4,
    title: "Przeróbka hydrauliki i elektryki",
    description:
      "Gdy jest taka potrzeba przerabiamy lub dorabiamy hydraulikę oraz elektrykę.",
    image: Hydraulika,
  },
];

export const REVIEWS: Review[] = [
  {
    id: 0,
    author: "Milena K.",
    date: "31 maja 2022",
    content:
      "Bardzo polecam, nie mam żadnych zastrzeżeń, solidnie, dokładnie i czysto.",
    stars: 5,
  },
  {
    id: 1,
    author: "Kamil P.",
    date: "2 maja 2022",
    content:
      "Pan Artek robił u mnie dwie łazienki i kotłownie, praca wykonana na najwyższym poziomie, równo, dokładnie, także polecam!",
    stars: 5,
  },
  {
    id: 2,
    author: "Marika B.",
    date: "20 kwietnia 2022",
    content:
      "Jestem zachwycona, fachowiec jakich mało! Pan Artur to profesjonalista z ogromną wiedzą, służy pomocą, doradza, oraz tłumaczy. Zakup materiałów również można pozostawić na jego głowie. Wykończenie łazienki było sprawne, dokładne i estetyczne. Polecam każdemu!",
    stars: 5,
  },
  {
    id: 3,
    author: "Mateusz R.",
    date: "16 kwietnia 2022",
    content:
      "Pełna profeska, osobiście miałem okazje się przekonać. Polecam w 100 procentach.",
    stars: 5,
  },
  {
    id: 4,
    author: "Iza K.",
    date: "22 października 2020",
    content:
      "Polecam. Usługa wykonana profesjonalnie i szybko. Efekt przeszedł moje oczekiwania.",
    stars: 5,
  },
  {
    id: 5,
    author: "Kamila K.",
    date: "1 października 2020",
    content:
      "Bardzo polecam. Terminowy, porządny fachowiec. Dokładny, wykonuje prace w czystości bardzo starannie. Piękne efekty.",
    stars: 5,
  },
  {
    id: 6,
    author: "Bożena H.",
    date: "18 lipca 2020",
    content:
      "Osoba godna polecenia. Rzetelny i konkretny, dotrzymuje terminów umowy, co dla większości ludzi jest sprawą nadrzędną. Ceny nie wygórowane, można liczyć na fachowe doradztwo podczas planowanego remontu.",
    stars: 5,
  },
  {
    id: 7,
    author: "Jacek K.",
    date: "15 lipca 2020",
    content:
      "Jestem bardzo zadowolony. Bardzo dokładny i solidny fachowiec, służący profesjonalnymi poradami. Łazienka wykonana terminowo, Pan Artur dopracował każdy szczegół.",
    stars: 5,
  },
  {
    id: 8,
    author: "Sylwia W.",
    date: "5 maja 2020",
    content:
      "Staranność, doświadczenie, wsparcie i doradztwo. Polecam. Zdjęcia w załączeniu",
    stars: 5,
  },
];

export const OFERTEO_LINK: string =
  "https://www.oferteo.pl/remart/firma/4257353";

export const OFFER_TILES_DATA: OfferTile[] = [
  {
    id: 0,
    title: "Łazienki",
    subtitle: "Twoja droga do pięknej i funkcjonalnej przestrzeni",
    description:
      "Remont całej łazienki to kompleksowa usługa polegająca na odnowieniu i poprawieniu wyglądu oraz funkcjonalności łazienki. Jest to proces, który może obejmować wiele różnych elementów, od zmiany układu przestrzeni po modernizację instalacji wodno-kanalizacyjnych.",
    works: [
      "Demontaż i usuwanie starej armatury i wyposażenia",
      "Prace instalacyjne",
      "Wybór materiałów",
      "Malowanie i wykończenie końcowe",
      "Montaż nowych elementów",
      "Płytki i wykończenie",
      "Czyszczenie i kontrola jakości",
    ],
    galleryTags: ["Łazienka"],
    imageUrl: BathroomImage,
  },

  {
    id: 1,
    title: "Malowanie i tynkowanie",
    subtitle: "Ożyw Twoje ściany",
    description:
      "Malowanie i tynkowanie to usługa, która ma na celu odświeżenie i poprawienie wyglądu wnętrz. Zarówno w przypadku mieszkań, domów, jak i biur czy przestrzeni komercyjnych, malowanie i tynkowanie są nieodzownymi elementami, aby stworzyć nowy wystrój lub odświeżyć obecny.",
    works: [
      "Przygotowanie powierzchni",
      "Wybór kolorów i rodzaju farb/tynków",
      "Tynkowanie",
      "Malowanie",
      "Szczegóły i wykończenie",
      "Sprzątanie i kontrola jakości",
    ],
    galleryTags: ["Malowanie", "Tynkowanie"],
    imageUrl: PaintingImage,
  },
  {
    id: 2,
    title: "Glazura i terakota",
    subtitle: "Podkreśl piękno przestrzeni",
    description:
      "Usługa glazury i terakoty obejmuje profesjonalne wykończenie ścian i podłóg, nadając pomieszczeniom nowoczesny i elegancki wygląd. Glazura i terakota to nie tylko praktyczne rozwiązania, ale również elementy, które mogą znacząco podnieść estetykę i wartość dowolnego wnętrza.",
    works: [
      "Przygotowanie powierzchni",
      "Montaż płytek na ścianach i podłogach",
      "Fugowanie",
      "Czyszczenie i kontrola jakości",
    ],
    galleryTags: ["Glazura", "Terakota", "Płytki"],
    imageUrl: Plytki,
  },
  {
    id: 3,
    title: "Panele podłogowe, deska barlinecka",
    subtitle: "Elegancja pod stopami",
    description:
      "Montaż paneli podłogowych i deski barlineckiej to usługa, która nadaje pomieszczeniom nie tylko estetyczny wygląd, ale także ciepło i naturalny charakter drewna. Te popularne rozwiązania podłogowe cieszą się dużą popularnością ze względu na swoją trwałość i możliwość aranżacji różnorodnych stylów wnętrz.",
    works: [
      "Przygotowanie powierzchni",
      "Montaż paneli lub desek",
      "Obróbka narożników i krawędzi",
      "Zakładanie listew",
      "Czyszczenie i kontrola jakości",
    ],
    galleryTags: ["Panele", "Deska"],
    imageUrl: Panele,
  },
  {
    id: 4,
    title: "Przeróbka elektryki i hydrauliki",
    subtitle: "Bezpieczeństwo i komfort Twojego domu",
    description:
      "Przeróbka elektryki i hydrauliki to kompleksowe działania mające na celu poprawę bezpieczeństwa i efektywności systemów elektrycznych oraz instalacji wodno-kanalizacyjnych w budynku. W rezultacie, modernizacja tych elementów przekłada się na komfort mieszkania, efektywne korzystanie z energii oraz eliminację potencjalnych zagrożeń.",
    works: [
      "Demontaż starych instalacji, jeśli jest taka potrzeba",
      "Przygotowanie powierzchni pod nowe instalacje",
      "Montaż nowych instalacji",
      "Testowanie i kontrola jakości",
    ],
    galleryTags: ["Hydraulika", "Elektryka"],
    imageUrl: Hydraulika,
  },
];
