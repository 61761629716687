import { motion, useAnimation, Variants } from "framer-motion";
import React, { FC } from "react";

interface HamburgerProps {
  isOpened: boolean;
  setIsOpened: React.Dispatch<boolean>;
}

const Hamburger: FC<HamburgerProps> = ({ isOpened, setIsOpened }) => {
  const HamburgerControls = useAnimation();

  const HamburgerVariants: Variants = {
    closed: {
      gap: "4px",
    },

    opened: {
      gap: "0px",
      transition: {
        duration: 0.1,
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  };

  const TopBarVariants: Variants = {
    closed: {
      transform: "rotate(0)",
      position: "relative",
    },
    opened: {
      transform: "rotate(45deg)",
      position: "absolute",
      left: "0",
      transition: {
        duration: 0.2,
        delay: 0.2,
      },
    },
  };

  const BottomBarVariants: Variants = {
    closed: {
      transform: "rotate(0)",
      position: "relative",
    },
    opened: {
      transform: "rotate(-45deg)",
      position: "absolute",
      left: "0",
      transition: {
        duration: 0.2,
        delay: 0.2,
      },
    },
  };

  const CenterBarVariants: Variants = {
    closed: {
      display: "block",
      opacity: 1,
    },
    opened: {
      display: "none",
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  const onClick = (): void => {
    HamburgerControls.start(!isOpened ? "opened" : "closed");
    setIsOpened(!isOpened);
  };

  return (
    <motion.div
      className="hamburger f-column"
      onClick={onClick}
      variants={HamburgerVariants}
      initial="closed"
      animate={HamburgerControls}
      data-opened={isOpened}
    >
      <motion.div
        initial="closed"
        animate={isOpened ? "opened" : "closed"}
        variants={TopBarVariants}
      ></motion.div>
      <motion.div
        variants={CenterBarVariants}
        initial="closed"
        animate={isOpened ? "opened" : "closed"}
      ></motion.div>
      <motion.div
        initial="closed"
        animate={isOpened ? "opened" : "closed"}
        variants={BottomBarVariants}
      ></motion.div>
    </motion.div>
  );
};

export default Hamburger;
