import React, { FC } from "react";
import { motion } from "framer-motion";

interface GalleryTagProps {
  text: string;
  onClick?: (text: string) => void;
  delay?: number;
  isActive: boolean;
  size?: "small" | "big";
}

const GalleryTag: FC<GalleryTagProps> = ({
  text,
  onClick,
  delay = 0,
  isActive,
  size,
}) => {
  return (
    <motion.div
      className="gallery-tag"
      onClick={() => onClick && onClick(text)}
      data-active={isActive}
      data-size={size}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          delay: delay,
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <span>{text}</span>
    </motion.div>
  );
};

export default GalleryTag;
