import React, { FC, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Offline from "../../components/Offline/Offline";
import CookiesModal from "../../components/CookiesModal/CookiesModal";

interface LayoutProps {
  invertPaths: string[];
}

const Layout: FC<LayoutProps> = ({ invertPaths }) => {
  const location = useLocation();
  const [isOnline, setIsOnline] = useState<boolean>(true);

  const onCookiesModalClose = () => {};

  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setIsOnline(true);
  });

  window.addEventListener("offline", () => {
    setIsOnline(false);
  });

  return (
    <>
      <Navigation invert={invertPaths.includes(location.pathname)} />
      {isOnline ? (
        <>
          <Outlet />
          <ScrollToTop />
        </>
      ) : (
        <Offline />
      )}
      <Footer />

      <CookiesModal onClose={onCookiesModalClose} />
    </>
  );
};

export default Layout;
