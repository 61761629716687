import React, { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as Dots } from "../../assets/svg/dots.svg";
import { motion, useInView } from "framer-motion";
import { CustomEase1, FadeUpVariants } from "../../config/variants";
import { Filters, getGallery } from "../../firebase/api/gallery/getGallery";
import GalleryItem from "../../interfaces/GalleryItem";
import { useSearchParams } from "react-router-dom";
import { getCategories } from "../../firebase/api/gallery/getCategories";
import { getGroups } from "../../firebase/api/gallery/getGroups";
import GalleryGroup from "../../interfaces/GalleryGroup";
import { FirebaseError } from "@firebase/util";
import GalleryWrapper from "../../components/GalleryWrapper/GalleryWrapper";
import GalleryFilters from "../../components/GalleryFilters/GalleryFilters";
import { useDebounce } from "usehooks-ts";

const Gallery: FC = () => {
  const [searchParams] = useSearchParams();
  const galleryEndRef = useRef<HTMLDivElement>(null);
  const galleryEndInView = useInView(galleryEndRef, {
    margin: "0px 0px 200px 0px",
  });

  const [tags, setTags] = useState<string[]>([]);
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [gallery, setGallery] = useState<GalleryItem[] | null>(null);
  const [galleryGroups, setGalleryGroups] = useState<GalleryGroup[] | null>(
    null
  );
  const [imagesAmount, setImagesAmount] = useState<number>(10);
  const [isGalleryLoading, setIsGalleryLoading] = useState<boolean>(true);
  const debounceSearchTags = useDebounce<string>(
    searchParams.get("tags") || "",
    500
  );
  const debounceSort = useDebounce<string>(searchParams.get("sort") || "", 500);

  useEffect(() => {
    if (!galleryEndInView || !gallery) return;

    setImagesAmount((prev) => {
      if (prev + 10 > gallery.length) return gallery.length;
      else return prev + 10;
    });
  }, [galleryEndInView]);

  useEffect(() => {
    const searchTags = searchParams.get("tags");

    const filters: Filters = {
      sort: searchParams.get("sort") || "newest",
      tags: searchTags ? searchTags.split(",") : [],
    };

    (async () => {
      setIsGalleryLoading(true);

      const requestSuccessCallback = (
        response: [GalleryItem[], GalleryGroup[]?]
      ): void => {
        const [g, gG] = response;
        setIsGalleryLoading(false);
        setGallery(g.filter((item) => !item?.isArchived));
        if (gG) setGalleryGroups(gG);
      };

      const requestErrorCallback = (error: FirebaseError): void => {
        console.log(error);
      };

      if (!galleryGroups) {
        await Promise.all([getGallery(filters), getGroups()])
          .then((response) => requestSuccessCallback(response))
          .catch((error) => requestErrorCallback(error));
      } else {
        requestSuccessCallback([await getGallery(filters)]);
      }
    })();
  }, [debounceSearchTags, debounceSort]);

  useEffect(() => {
    (async () => {
      const c = await getCategories();
      setTags(c);
    })();
  }, []);

  useEffect(() => {
    const searchTags = searchParams.get("tags");
    setActiveTags(searchTags ? searchTags.split(",") : []);
  }, [searchParams.get("tags")]);

  return (
    <section id="gallery-view">
      <Dots className="dots" />
      <main className="content f-column y-start">
        <div className="header-content f-column y-start x-start">
          <motion.header
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Galeria
          </motion.header>

          <motion.p
            initial="initial"
            animate="fadeUp"
            variants={FadeUpVariants}
            transition={{
              delay: 0.7,
              duration: 0.8,
              ease: CustomEase1,
              opacity: { duration: 1.5 },
            }}
          >
            Zdjęcia wykonane podczas remontów u naszych klientów.
          </motion.p>
        </div>

        <GalleryFilters tags={tags} activeTags={activeTags} />

        <GalleryWrapper
          isLoading={isGalleryLoading}
          gallery={gallery?.slice(0, imagesAmount)}
          imagesCount={gallery?.length || 0}
        />
        <div ref={galleryEndRef} className="end-of-gallery"></div>
      </main>
    </section>
  );
};

export default Gallery;
