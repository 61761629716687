import React, { FC, useLayoutEffect, useState } from "react";
import Modal, { ModalProps } from "../Modal/Modal";
import Button from "../Button/Button";
import Cookie from "js-cookie";
import { COOKIES_AGREEMENT_COOKIE } from "../../config";
import { motion, Variants } from "framer-motion";
import Switch from "../Switch/Switch";
import HotjarLoad from "../HotjarLoad/HotjarLoad";

const InfoBoxVariants: Variants = {
  hidden: {
    display: "none",
    x: "-100%",
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      display: {
        duration: 0,
      },
    },
  },
  shown: {
    display: "flex",
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      display: {
        duration: 0,
      },
    },
  },
};
const ManageBoxVariants: Variants = {
  hidden: {
    display: "none",
    x: "100%",
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      display: {
        duration: 0,
      },
    },
  },
  shown: {
    display: "flex",
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      display: {
        duration: 0,
      },
    },
  },
};

interface CookiesModalProps extends ModalProps {}

const CookiesModal: FC<CookiesModalProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isManaging, setIsManaging] = useState<boolean>(false);
  const [acceptedCookies, setAcceptedCookies] = useState({
    accepted: true,
    analytics: false,
  });

  const SHOW_COOKIES_MODAL_DELAY: number = 5 * 1000;

  const onAnalyticsConsented = () => {
    setAcceptedCookies({ analytics: true, accepted: true });
  };

  useLayoutEffect(() => {
    const cookie = JSON.parse(Cookie.get(COOKIES_AGREEMENT_COOKIE) || "{}");
    if (!cookie?.accepted) {
      setTimeout(() => setIsVisible(true), SHOW_COOKIES_MODAL_DELAY);
    } else {
      if (cookie?.analytics) {
        onAnalyticsConsented();
      }
    }
  }, []);

  const onAcceptAllClick = () => {
    const accepted = {
      accepted: true,
      analytics: true,
    };

    Cookie.set(COOKIES_AGREEMENT_COOKIE, JSON.stringify(accepted), {
      expires: 365,
    });
    setIsVisible(false);
    onAnalyticsConsented();
  };

  const onManageClick = () => {
    setIsManaging(true);
  };

  const onAccept = () => {
    Cookie.set(COOKIES_AGREEMENT_COOKIE, JSON.stringify(acceptedCookies), {
      expires: 365,
    });
    setIsVisible(false);

    if (acceptedCookies.analytics) {
      onAnalyticsConsented();
    }
  };

  const onSwitchChange = (key: string, status: boolean) => {
    setAcceptedCookies((prev) => ({ ...prev, [key]: status }));
  };

  return (
    <>
      <Modal onClose={onClose} visible={isVisible} id="cookies-modal">
        <div className="cookies-modal-wrapper flex">
          <motion.div
            variants={InfoBoxVariants}
            initial="shown"
            animate={isManaging ? "hidden" : "shown"}
            className="cookies-modal-content cookies-modal-info flex f-column y-start x-start"
          >
            <header>Polityka ciasteczek</header>
            <p>
              Chcemy poinformować Cię o wykorzystywaniu plików cookies oraz
              zapewnić Ci pełną transparentność w kwestii ochrony Twojej
              prywatności. Korzystając z naszej strony, wyrażasz zgodę na użycie
              plików cookies zgodnie z naszą polityką prywatności. Jeśli nie
              zgadzasz się na używanie plików cookies, możesz zmienić ustawienia
              swojej przeglądarki, aby odrzucała pliki cookies lub informowała
              Cię o ich wysyłaniu. Należy jednak pamiętać, że wyłączenie plików
              cookies może wpłynąć na działanie strony.
            </p>

            <div className="cookies-modal-buttons flex f-column">
              <Button
                text="Akceptuj wszystkie"
                action="callback"
                callback={onAcceptAllClick}
                filled
              />

              <Button
                text="Zarządzaj"
                action="callback"
                callback={onManageClick}
              />
            </div>
          </motion.div>

          <motion.div
            variants={ManageBoxVariants}
            initial="hidden"
            animate={isManaging ? "shown" : "hidden"}
            className="cookies-modal-content cookies-modal-manager flex f-column y-start x-between"
          >
            <header>Zarządzaj ciasteczkami</header>

            <div className="cookies-manager flex f-column">
              <div className="cookies-row flex f-column">
                <div className="row-top-bar flex x-between">
                  <span>Niezbędne</span>
                  <Switch disabled on />
                </div>

                <p>
                  Są to niezbędne pliki cookies, które umożliwiają Ci
                  korzystanie z podstawowych funkcji strony, takich jak
                  nawigacja po stronie, dostęp do zabezpieczonych obszarów itp.
                  Bez tych plików cookies strona nie będzie działać poprawnie.
                </p>
              </div>

              <div className="cookies-row flex f-column">
                <div className="row-top-bar flex x-between">
                  <span>Analityczne</span>
                  <Switch
                    onChange={(status) => onSwitchChange("analytics", status)}
                  />
                </div>

                <p>
                  Pomagają nam analizować sposób, w jaki użytkownicy korzystają
                  z naszej strony, jakie treści są najbardziej popularne, jak
                  długo spędzają czas na poszczególnych stronach itp. Te
                  informacje są anonimowe i służą nam do ulepszania jakości
                  naszej strony i dostosowania jej do potrzeb użytkowników.
                </p>
              </div>
            </div>

            <Button
              text="Zatwierdź"
              action="callback"
              callback={onAccept}
              filled
            />
          </motion.div>
        </div>
      </Modal>
      {acceptedCookies.analytics && <HotjarLoad />}
    </>
  );
};

export default CookiesModal;
