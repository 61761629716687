import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../init";
import { STORAGE_FOLDERS } from "../../config";

export const deleteImage = async (
  uuid: string,
  successCallback: () => void
): Promise<void> => {
  const galleryImageRef = ref(storage, STORAGE_FOLDERS.GALLERY + "/" + uuid);
  await deleteObject(galleryImageRef)
    .then(async () => {
      const galleryThumbnailImageRef = ref(
        storage,
        STORAGE_FOLDERS.GALLERY + "/thumbnails/" + uuid + "_128x128"
      );
      await deleteObject(galleryThumbnailImageRef)
        .then(() => {
          successCallback();
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};
