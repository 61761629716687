import React, { FC, useEffect } from "react";
import OfferTile from "../../interfaces/OfferTile";
import { ReactComponent as HammerIcon } from "../../assets/svg/hammer.svg";
import Button from "../Button/Button";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { RevealVariants } from "../../views/Contact/Variants";
import { FadeRightVariants, FadeUpVariants } from "../../config/variants";

interface OfferTileItemProps {
  data: OfferTile;
}

const OfferTileItem: FC<OfferTileItemProps> = ({ data }) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const e = document.getElementById(searchParams.get("id") || "");
    if (e) {
      e.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [searchParams]);

  return (
    <motion.div
      className="offer-tile-item"
      id={data.title.toLowerCase().replaceAll(" ", "-")}
    >
      <motion.div
        className="offer-tile-image flex"
        style={{ backgroundImage: `url(${data.imageUrl})` }}
      ></motion.div>
      <div className="offer-tile-content f-column y-start x-start">
        <div className="offer-tile-header-content f-column">
          <header>{data.title}</header>

          <small>
            <motion.small
              variants={RevealVariants}
              whileInView="reveal"
              viewport={{ once: true }}
              initial="initial"
              transition={{
                duration: 0.2,
                delay: 0.2,
              }}
            >
              {data.subtitle}
            </motion.small>
          </small>
        </div>

        <div className="motion-p">
          <motion.p
            className="offer-tile-description"
            variants={FadeRightVariants}
            whileInView="fadeRight"
            viewport={{ once: true }}
            initial="initial"
            transition={{
              duration: 0.2,
              delay: 0.4,
            }}
          >
            {data.description}
          </motion.p>
        </div>

        <div className="offer-tile-works-content f-column">
          <span>
            <motion.span
              className="offer-tile-works-title"
              variants={RevealVariants}
              whileInView="reveal"
              viewport={{ once: true }}
              initial="initial"
              transition={{
                duration: 0.2,
                delay: 0.7,
              }}
            >
              Remont może obejmować prace tj.:
            </motion.span>
          </span>

          <div className="offer-tile-works">
            {data.works.map((work, i) => (
              <div className="motion-work" key={"work-" + i}>
                <motion.div
                  className="offer-tile-work flex y-center"
                  variants={RevealVariants}
                  whileInView="reveal"
                  viewport={{ once: true }}
                  initial="initial"
                  transition={{
                    duration: 0.2,
                    delay: 0.7 + 0.1 * i,
                  }}
                >
                  <HammerIcon className="icon" />
                  <span>{work}</span>
                </motion.div>
              </div>
            ))}
          </div>
        </div>

        <motion.div
          className="button-wrapper"
          variants={FadeUpVariants}
          whileInView="fadeUp"
          viewport={{ once: true }}
          initial="initial"
          transition={{
            duration: 0.2,
            delay: 0.7 + 0.1 * data.works.length,
          }}
        >
          <Button
            text="Zobacz zdjęcia"
            action="link"
            path={
              `/galeria?` +
              createSearchParams({
                tags: data.galleryTags
                  .map((tag) => tag.toLowerCase())
                  .join(","),
              })
            }
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default OfferTileItem;
