import React, { createContext, FC, useLayoutEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth as firebaseAuth } from "../firebase/init";
import { useNavigate } from "react-router-dom";
import Auth from "../interfaces/Auth";
import { LoginResult, signUp } from "../firebase/api/auth/signUp";
import { signOut } from "../firebase/api/auth/signOut";
import Cookie from "js-cookie";
import { AUTH_COOKIE } from "../config";

const AuthContext = createContext<{
  auth: Auth;
  login: (payload: { email: string; passwd: string }) => Promise<LoginResult>;
  logout: (callback: () => void) => Promise<void>;
}>({
  auth: {} as Auth,
  // @ts-ignore
  login: async (payload: { email: string; passwd: string }) => {},
  logout: async (callback: () => void) => {},
});

export const AuthProvider: FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const INITIAL_STATE = {};
  const navigate = useNavigate();
  const [auth, setAuth] = useState<Auth>(INITIAL_STATE as Auth);

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (!currentUser) {
        setAuth({} as Auth);
        return;
      }

      currentUser.getIdToken(false).then((token) => {
        Cookie.set(AUTH_COOKIE, token, { path: "/admin/panel" });

        setAuth({
          email: currentUser?.email || "empty",
          displayName: currentUser?.displayName || "Anonymous",
          id: currentUser?.uid || "0",
          accessToken: token,
        });
      });
    });
    return () => unsubscribe();
  }, []);

  const login = async (payload: {
    email: string;
    passwd: string;
  }): Promise<LoginResult> => {
    if (!payload)
      return { status: "error", error: "Wystąpił błąd! Brak danych." };

    const result = await signUp(payload);

    if (result.user) {
      const {
        user: { user },
      } = result;

      user.getIdToken(true).then((token) => {
        Cookie.set(AUTH_COOKIE, token);

        setAuth({
          email: user?.email || "empty",
          id: user?.uid || "0",
          displayName: user?.displayName || "Anonymous",
          accessToken: token,
        });
      });
    }

    return result;
  };

  const logout = async (callback: () => void): Promise<void> => {
    Cookie.remove(AUTH_COOKIE);
    await signOut(callback);
    setAuth({} as Auth);
    navigate("/admin/panel/login");
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
