import { motion, Variants } from "framer-motion";
import React, { FC } from "react";

interface LandingOfferImageProps {
  isActive: boolean;
  image: any;
}

const LandingOfferImage: FC<LandingOfferImageProps> = ({ image, isActive }) => {
  const OfferImageVariants: Variants = {
    initial: {
      opacity: 0,
    },
    fadeIn: {
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="landing-offer-image"
      style={{ backgroundImage: `url(${image})` }}
      variants={OfferImageVariants}
      initial="initial"
      animate={isActive ? "fadeIn" : "initial"}
    ></motion.div>
  );
};

export default LandingOfferImage;
