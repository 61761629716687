import {
  motion,
  useMotionValueEvent,
  useScroll,
  Variants,
} from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";
import { useDebounce } from "usehooks-ts";

const ScrollToTop: FC = () => {
  const { scrollY } = useScroll();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentScrollY, setCurrentScrollY] = useState<number>(0);
  const debouncedScrollY = useDebounce(currentScrollY, 300);

  useMotionValueEvent(scrollY, "change", (latest) => {
    setCurrentScrollY(latest);
  });

  useEffect(() => {
    if (!isVisible && debouncedScrollY >= window.innerHeight / 2)
      setIsVisible(true);
    else if (isVisible && !(debouncedScrollY >= window.innerHeight / 2))
      setIsVisible(false);
  }, [debouncedScrollY]);

  const ScrollVariants: Variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
      transitionEnd: {
        visibility: "hidden",
      },
    },

    shown: {
      opacity: 1,
      visibility: "visible",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };

  const onClick = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsVisible(false);
  };

  return (
    <motion.div
      className="scroll-to-top flex y-center x-center"
      variants={ScrollVariants}
      initial="hidden"
      animate={isVisible ? "shown" : "hidden"}
      onClick={onClick}
      id="scroll-to-top"
    >
      <ArrowIcon className="icon" width={32} height={32} />
    </motion.div>
  );
};

export default ScrollToTop;
